import produce from 'immer'
import {
  LOAD_LIST_MEMBER_REQUEST,
  LOAD_LIST_MEMBER_SUCCESS,
  LOAD_LIST_MEMBER_FAILURE,
  LOAD_LIST_MEMBER_IDLE,
  LOAD_LIST_COMPANY_FAILURE,
  LOAD_LIST_COMPANY_IDLE,
  LOAD_LIST_COMPANY_REQUEST,
  LOAD_LIST_COMPANY_SUCCESS,
  LOAD_MEMBER_FAILURE,
  LOAD_MEMBER_REQUEST,
  LOAD_MEMBER_SUCCESS,
  LOAD_MEMBER_IDLE,
  CREATE_MEMBER_REQUEST,
  CREATE_MEMBER_SUCCESS,
  CREATE_MEMBER_FAILURE,
  CREATE_MEMBER_IDLE,
  UPDATE_MEMBER_REQUEST,
  UPDATE_MEMBER_SUCCESS,
  UPDATE_MEMBER_FAILURE,
  UPDATE_MEMBER_IDLE,
  DELETE_MEMBER_REQUEST,
  DELETE_MEMBER_SUCCESS,
  DELETE_MEMBER_FAILURE,
  DELETE_MEMBER_IDLE,
  LOAD_LIST_PROVINCE_REQUEST,
  LOAD_LIST_PROVINCE_SUCCESS,
  LOAD_LIST_PROVINCE_FAILURE,
  LOAD_LIST_PROVINCE_IDLE,
} from '../../actions/member'

import { FETCH_STATUS_IDLE, FETCH_STATUS_REQUEST, FETCH_STATUS_SUCCESS, FETCH_STATUS_FAILURE } from '../../utils'

import { SOCKET_RESPONSE_SEARCH_LIST_MEMBERS } from '../../actions/socket'

const initialState = {
  listMember: [],
  totalItems: 0,
  totalPages: 1,
  page: 1,
  memberData: undefined,
  loadListMember: {
    fetchStatus: FETCH_STATUS_IDLE,
    error: undefined,
  },
  listCompany: [],
  loadListCompany: {
    fetchStatus: FETCH_STATUS_IDLE,
    error: undefined,
  },
  listProvince: [],
  loadListProvince: {
    fetchStatus: FETCH_STATUS_IDLE,
    error: undefined,
  },
  loadMember: {
    fetchStatus: FETCH_STATUS_IDLE,
    error: undefined,
  },
  createMember: {
    fetchStatus: FETCH_STATUS_IDLE,
    error: undefined,
  },
  updateMember: {
    fetchStatus: FETCH_STATUS_IDLE,
    error: undefined,
  },
  deleteMember: {
    fetchStatus: FETCH_STATUS_IDLE,
    error: undefined,
  },
}

const member = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_LIST_MEMBER_REQUEST: {
      return produce(state, draftState => {
        draftState.loadListMember.fetchStatus = FETCH_STATUS_REQUEST
      })
    }
    case LOAD_LIST_MEMBER_SUCCESS: {
      return produce(state, draftState => {
        draftState.loadListMember.fetchStatus = FETCH_STATUS_SUCCESS
        draftState.listMember = action.payload.data
        draftState.totalItems = action.payload.totalItems
        draftState.totalPages = action.payload.totalPages
        draftState.page = action.payload.page
      })
    }
    case LOAD_LIST_MEMBER_FAILURE: {
      return produce(state, draftState => {
        draftState.loadListMember.fetchStatus = FETCH_STATUS_FAILURE
        draftState.loadListMember.error = action.payload.error
      })
    }
    case LOAD_LIST_MEMBER_IDLE: {
      return produce(state, draftState => {
        draftState.loadListMember.fetchStatus = FETCH_STATUS_IDLE
      })
    }
    case SOCKET_RESPONSE_SEARCH_LIST_MEMBERS: {
      return produce(state, draftState => {
        draftState.listMember = action.payload.data
        draftState.totalItems = action.payload.totalItems
        draftState.totalPages = action.payload.totalPages
        draftState.page = action.payload.page
      })
    }
    case LOAD_LIST_PROVINCE_REQUEST: {
      return produce(state, draftState => {
        draftState.loadListProvince.fetchStatus = FETCH_STATUS_REQUEST
      })
    }
    case LOAD_LIST_PROVINCE_SUCCESS: {
      return produce(state, draftState => {
        draftState.loadListProvince.fetchStatus = FETCH_STATUS_SUCCESS
        draftState.listProvince = action.payload.listProvince.map(origin => ({
          text: origin,
          value: origin,
        }))
      })
    }
    case LOAD_LIST_PROVINCE_FAILURE: {
      return produce(state, draftState => {
        draftState.loadListProvince.fetchStatus = FETCH_STATUS_FAILURE
        draftState.loadListProvince.error = action.payload.error
      })
    }
    case LOAD_LIST_PROVINCE_IDLE: {
      return produce(state, draftState => {
        draftState.loadListProvince.fetchStatus = FETCH_STATUS_IDLE
      })
    }
    case LOAD_LIST_COMPANY_REQUEST: {
      return produce(state, draftState => {
        draftState.loadListCompany.fetchStatus = FETCH_STATUS_REQUEST
      })
    }
    case LOAD_LIST_COMPANY_SUCCESS: {
      return produce(state, draftState => {
        draftState.loadListCompany.fetchStatus = FETCH_STATUS_SUCCESS
        draftState.listCompany = action.payload.listDestination.map(destination => ({
          text: destination.company.nameEn,
          value: destination.id,
        }))
      })
    }
    case LOAD_LIST_COMPANY_FAILURE: {
      return produce(state, draftState => {
        draftState.loadListCompany.fetchStatus = FETCH_STATUS_FAILURE
        draftState.loadListCompany.error = action.payload.error
      })
    }
    case LOAD_LIST_COMPANY_IDLE: {
      return produce(state, draftState => {
        draftState.loadListCompany.fetchStatus = FETCH_STATUS_IDLE
      })
    }

    case LOAD_MEMBER_REQUEST: {
      return produce(state, draftState => {
        draftState.loadMember.fetchStatus = FETCH_STATUS_REQUEST
      })
    }
    case LOAD_MEMBER_SUCCESS: {
      return produce(state, draftState => {
        draftState.loadMember.fetchStatus = FETCH_STATUS_SUCCESS
        draftState.memberData = action.payload.memberData
      })
    }
    case LOAD_MEMBER_FAILURE: {
      return produce(state, draftState => {
        draftState.loadMember.fetchStatus = FETCH_STATUS_FAILURE
        draftState.loadMember.error = action.payload.error
      })
    }
    case LOAD_MEMBER_IDLE: {
      return produce(state, draftState => {
        draftState.loadMember.fetchStatus = FETCH_STATUS_IDLE
      })
    }
    case CREATE_MEMBER_REQUEST: {
      return produce(state, draftState => {
        draftState.createMember.fetchStatus = FETCH_STATUS_REQUEST
      })
    }
    case CREATE_MEMBER_SUCCESS: {
      return produce(state, draftState => {
        draftState.createMember.fetchStatus = FETCH_STATUS_SUCCESS
      })
    }
    case CREATE_MEMBER_FAILURE: {
      return produce(state, draftState => {
        draftState.createMember.fetchStatus = FETCH_STATUS_FAILURE
        draftState.createMember.error = action.payload.error
      })
    }
    case CREATE_MEMBER_IDLE: {
      return produce(state, draftState => {
        draftState.createMember.fetchStatus = FETCH_STATUS_IDLE
      })
    }
    case UPDATE_MEMBER_REQUEST: {
      return produce(state, draftState => {
        draftState.updateMember.fetchStatus = FETCH_STATUS_REQUEST
      })
    }
    case UPDATE_MEMBER_SUCCESS: {
      return produce(state, draftState => {
        draftState.updateMember.fetchStatus = FETCH_STATUS_SUCCESS
      })
    }
    case UPDATE_MEMBER_FAILURE: {
      return produce(state, draftState => {
        draftState.updateMember.fetchStatus = FETCH_STATUS_FAILURE
        draftState.updateMember.error = action.payload.error
      })
    }
    case UPDATE_MEMBER_IDLE: {
      return produce(state, draftState => {
        draftState.updateMember.fetchStatus = FETCH_STATUS_IDLE
      })
    }
    case DELETE_MEMBER_REQUEST: {
      return produce(state, draftState => {
        draftState.deleteMember.fetchStatus = FETCH_STATUS_REQUEST
      })
    }
    case DELETE_MEMBER_SUCCESS: {
      return produce(state, draftState => {
        draftState.deleteMember.fetchStatus = FETCH_STATUS_SUCCESS
      })
    }
    case DELETE_MEMBER_FAILURE: {
      return produce(state, draftState => {
        draftState.deleteMember.fetchStatus = FETCH_STATUS_FAILURE
        draftState.deleteMember.error = action.payload.error
      })
    }
    case DELETE_MEMBER_IDLE: {
      return produce(state, draftState => {
        draftState.deleteMember.fetchStatus = FETCH_STATUS_IDLE
      })
    }
    default: {
      return state
    }
  }
}

export default member
