import { get } from '../../utils'
import { API_LIST_NOTIFICATION } from '../api-constraint'

export const LOAD_LIST_NOTIFICATION_REQUEST = 'LOAD_LIST_NOTIFICATION_REQUEST'
export const LOAD_LIST_NOTIFICATION_SUCCESS = 'LOAD_LIST_NOTIFICATION_SUCCESS'
export const LOAD_LIST_NOTIFICATION_FAILURE = 'LOAD_LIST_NOTIFICATION_FAILURE'
export const LOAD_LIST_NOTIFICATION_IDLE = 'LOAD_LIST_NOTIFICATION_IDLE'

export const loadListNotificationRequest = () => {
  return {
    type: LOAD_LIST_NOTIFICATION_REQUEST,
  }
}

export const loadListNotificationSuccess = listNotification => {
  return {
    type: LOAD_LIST_NOTIFICATION_SUCCESS,
    payload: {
      listNotification,
    },
  }
}

export const loadListNotificationFailure = error => {
  return {
    type: LOAD_LIST_NOTIFICATION_FAILURE,
    payload: {
      error,
    },
  }
}

export const loadListNotificationIdle = () => {
  return {
    type: LOAD_LIST_NOTIFICATION_IDLE,
  }
}

export const loadListNotification = () => {
  return async dispatch => {
    dispatch(loadListNotificationRequest())
    try {
      const listNotification = await get({
        path: API_LIST_NOTIFICATION,
      })
      dispatch(loadListNotificationSuccess(listNotification))
    } catch (err) {
      dispatch(loadListNotificationFailure(err))
    } finally {
      dispatch(loadListNotificationIdle())
    }
  }
}
