import produce from 'immer'
import {
  LOAD_TRAFFIC_DASHBOARD_REQUEST,
  LOAD_TRAFFIC_DASHBOARD_SUCCESS,
  LOAD_TRAFFIC_DASHBOARD_FAILURE,
  LOAD_TRAFFIC_DASHBOARD_IDLE,
} from '../../actions/trafficcontrol'

import { SOCKET_TRAFFIC_DASHBOARD } from '../../actions/socket'

import { FETCH_STATUS_REQUEST, FETCH_STATUS_SUCCESS, FETCH_STATUS_FAILURE, FETCH_STATUS_IDLE } from '../../utils'

const INIT_RANKING_MEMBER = [
  {
    company: '1st',
    count: 0,
  },
  {
    company: '2nd',
    count: 0,
  },
  {
    company: '3rd',
    count: 0,
  },
  {
    company: '4th',
    count: 0,
  },
  {
    company: '5th',
    count: 0,
  },
]

const initialState = {
  latestVehicleData: undefined,
  vehicleCountLastHour: 0,
  vehicleCountNumber: 0,
  maxVehicleCountNumber: 10000,
  vehicleCountByType: [],
  vehicleCountByDestList: [],
  vehicleCountOnPeakData: { label: '', count: 0 },
  vehicleCountOffPeakData: { label: '', count: 0 },
  vehicleCountPerHourList: [],
  vehicleCountGroupByPurposeData: { total: 0, data: [] },
  vehicleCountGroupByIsMemberData: { total: 0, data: [] },
  greenGasValue: 0,
  maxGreenGasValue: 10000,
  avgTimeSpentValue: { hours: 0, minutes: 0 },
  listRankCompanyVehicleMember: [],
  loadTrafficDashboardStatus: {
    date: undefined,
    fetchStatus: FETCH_STATUS_IDLE,
    error: undefined,
  },
}

const transformVehicleCountData = vehicleCountData => {
  const { data, total } = vehicleCountData
  const dataTransform = data.map(eachKey => {
    return {
      name: eachKey.name,
      value: eachKey.count,
      percentage: eachKey.percentage,
    }
  })

  return {
    total,
    data: dataTransform,
  }
}

const transformVehicleCountByDestinationList = vehicleCountByDestList => {
  const output = vehicleCountByDestList.map(data => {
    return {
      label: data.label,
      ...data.data,
    }
  })
  return output.filter(each => Object.keys(each).length > 1)
}

const transformVehicleCountPerHourList = vehicleCountPerHourList => {
  const output = vehicleCountPerHourList.map(each => {
    const vehicleCountForecastData = each.data
    const eachLabelOutput = Object.keys(vehicleCountForecastData).reduce((prev, currentKey) => {
      const forecastValue = vehicleCountForecastData[currentKey].forecast - vehicleCountForecastData[currentKey].count
      return {
        ...prev,
        [currentKey]: vehicleCountForecastData[currentKey].count,
        [`${currentKey} forecast`]: forecastValue > 0 ? forecastValue : 0,
        [`${currentKey} forecast raw`]: vehicleCountForecastData[currentKey].forecast,
      }
    }, {})
    return {
      label: each.label,
      ...eachLabelOutput,
    }
  })
  return output
}

const prefillDataMemberRanking = data => {
  const tempArray = [...data].sort((a, b) => {
    return b.count - a.count
  })
  const newData = tempArray.reduce((prev, currentValue, index) => {
    const temp = [...prev]
    temp[index] = currentValue
    return temp
  }, INIT_RANKING_MEMBER)
  return newData
}

const trafficcontrol = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_TRAFFIC_DASHBOARD_REQUEST: {
      return produce(state, draftState => {
        draftState.loadTrafficDashboardStatus.fetchStatus = FETCH_STATUS_REQUEST
        draftState.loadTrafficDashboardStatus.date = action.payload.date
      })
    }
    case LOAD_TRAFFIC_DASHBOARD_SUCCESS: {
      return produce(state, draftState => {
        draftState.latestVehicleData = action.payload.latestVehicleData
        draftState.loadTrafficDashboardStatus.fetchStatus = FETCH_STATUS_SUCCESS
        draftState.vehicleCountLastHour = action.payload.vehicleCountLastHour
        draftState.vehicleCountNumber = action.payload.vehicleCountNumber
        draftState.maxVehicleCountNumber = action.payload.maxVehicleCountNumber
        draftState.vehicleCountByType = action.payload.vehicleCountByTypeList
        draftState.vehicleCountByDestList = transformVehicleCountByDestinationList(action.payload.vehicleCountByDestList)
        draftState.vehicleCountOnPeakData = action.payload.vehicleCountOnPeakData
        draftState.vehicleCountOffPeakData = action.payload.vehicleCountOffPeakData
        draftState.vehicleCountPerHourList = transformVehicleCountPerHourList(action.payload.vehicleCountPerHourList)
        draftState.vehicleCountGroupByPurposeData = transformVehicleCountData(action.payload.vehicleCountGroupByPurposeData)
        draftState.vehicleCountGroupByIsMemberData = transformVehicleCountData(action.payload.vehicleCountGroupByIsMemberData)
        draftState.greenGasValue = action.payload.greenGasValue
        draftState.maxGreenGasValue = action.payload.maxGreenGasValue
        draftState.avgTimeSpentValue = action.payload.avgTimeSpentValue
        draftState.listRankCompanyVehicleMember = prefillDataMemberRanking(action.payload.listRankCompanyVehicleMember)
      })
    }
    case LOAD_TRAFFIC_DASHBOARD_FAILURE: {
      return produce(state, draftState => {
        draftState.loadTrafficDashboardStatus.fetchStatus = FETCH_STATUS_FAILURE
        draftState.loadTrafficDashboardStatus.error = action.payload.error
      })
    }
    case LOAD_TRAFFIC_DASHBOARD_IDLE: {
      return produce(state, draftState => {
        draftState.loadTrafficDashboardStatus.fetchStatus = FETCH_STATUS_IDLE
      })
    }
    case SOCKET_TRAFFIC_DASHBOARD: {
      return produce(state, draftState => {
        draftState.latestVehicleData = action.payload.latestVehicleData || initialState.latestVehicleData
        draftState.loadTrafficDashboardStatus.fetchStatus = FETCH_STATUS_SUCCESS
        draftState.vehicleCountLastHour = action.payload.vehicleCountLastHour
        draftState.vehicleCountNumber = action.payload.vehicleCountNumber
        draftState.maxVehicleCountNumber = action.payload.maxVehicleCountNumber
        draftState.vehicleCountByType = action.payload.vehicleCountByTypeList
        draftState.vehicleCountByDestList = transformVehicleCountByDestinationList(action.payload.vehicleCountByDestList)
        draftState.vehicleCountOnPeakData = action.payload.vehicleCountOnPeakData
        draftState.vehicleCountOffPeakData = action.payload.vehicleCountOffPeakData
        draftState.vehicleCountPerHourList = transformVehicleCountPerHourList(action.payload.vehicleCountPerHourList)
        draftState.vehicleCountGroupByPurposeData = transformVehicleCountData(action.payload.vehicleCountGroupByPurposeData)
        draftState.vehicleCountGroupByIsMemberData = transformVehicleCountData(action.payload.vehicleCountGroupByIsMemberData)
        draftState.greenGasValue = action.payload.greenGasValue
        draftState.maxGreenGasValue = action.payload.maxGreenGasValue
        draftState.avgTimeSpentValue = action.payload.avgTimeSpentValue
        draftState.listRankCompanyVehicleMember = prefillDataMemberRanking(action.payload.listRankCompanyVehicleMember)
      })
    }
    default: {
      return state
    }
  }
}

export default trafficcontrol
