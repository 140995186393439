import { get, post, patch, del } from '../../utils'
import { API_USER_MANAGEMENT, API_REGISTER_USER_MANAGEMENT, API_USER_MANAGEMENT_ROLE } from '../api-constraint'

export const LOAD_LIST_USER_REQUEST = 'LOAD_LIST_USER_REQUEST'
export const LOAD_LIST_USER_SUCCESS = 'LOAD_LIST_USER_SUCCESS'
export const LOAD_LIST_USER_FAILURE = 'LOAD_LIST_USER_FAILURE'
export const LOAD_LIST_USER_IDLE = 'LOAD_LIST_USER_IDLE'

export const loadListUserRequest = () => {
  return {
    type: LOAD_LIST_USER_REQUEST,
  }
}

export const loadListUserSuccess = listRoleUser => {
  return {
    type: LOAD_LIST_USER_SUCCESS,
    payload: {
      listRoleUser,
    },
  }
}

export const loadListUserFailure = error => {
  return {
    type: LOAD_LIST_USER_FAILURE,
    payload: {
      error,
    },
  }
}

export const loadListUserIdle = () => {
  return {
    type: LOAD_LIST_USER_IDLE,
  }
}

export const loadListUser = (options = {}) => {
  return async dispatch => {
    const { cookie } = options
    dispatch(loadListUserRequest())
    try {
      const listRoleUser = await get({
        path: `${API_USER_MANAGEMENT}`,
        headers: cookie ? { cookie } : undefined,
      })
      dispatch(loadListUserSuccess(listRoleUser))
    } catch (err) {
      dispatch(loadListUserFailure(err))
    } finally {
      dispatch(loadListUserIdle())
    }
  }
}

export const REGISTER_USER_REQUEST = 'REGISTER_USER_REQUEST'
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS'
export const REGISTER_USER_FAILURE = 'REGISTER_USER_FAILURE'
export const REGISTER_USER_IDLE = 'REGISTER_USER_IDLE'

export const registerUserRequest = userData => {
  return {
    type: REGISTER_USER_REQUEST,
    payload: {
      userData,
    },
  }
}

export const registerUserSuccess = userData => {
  return {
    type: REGISTER_USER_SUCCESS,
    payload: {
      userData,
    },
  }
}

export const registerUserFailure = error => {
  return {
    type: REGISTER_USER_FAILURE,
    payload: {
      error,
    },
  }
}

export const registerUserIdle = () => {
  return {
    type: REGISTER_USER_IDLE,
  }
}

export const registerUser = data => {
  return async dispatch => {
    dispatch(registerUserRequest(data))
    try {
      const createdUserData = await post({
        path: `${API_REGISTER_USER_MANAGEMENT}`,
        body: data,
      })
      dispatch(registerUserSuccess(createdUserData))
    } catch (err) {
      dispatch(registerUserFailure(err))
    } finally {
      dispatch(registerUserIdle())
    }
  }
}

export const LOAD_LIST_USER_ROLE_REQUEST = 'LOAD_LIST_USER_ROLE_REQUEST'
export const LOAD_LIST_USER_ROLE_SUCCESS = 'LOAD_LIST_USER_ROLE_SUCCESS'
export const LOAD_LIST_USER_ROLE_FAILURE = 'LOAD_LIST_USER_ROLE_FAILURE'
export const LOAD_LIST_USER_ROLE_IDLE = 'LOAD_LIST_USER_ROLE_IDLE'

export const loadListUserRoleRequest = () => {
  return {
    type: LOAD_LIST_USER_ROLE_REQUEST,
  }
}

export const loadListUserRoleSuccess = listRole => {
  return {
    type: LOAD_LIST_USER_ROLE_SUCCESS,
    payload: {
      listRole,
    },
  }
}

export const loadListUserRoleFailure = error => {
  return {
    type: LOAD_LIST_USER_ROLE_FAILURE,
    payload: {
      error,
    },
  }
}

export const loadListUserRoleIdle = () => {
  return {
    type: LOAD_LIST_USER_ROLE_IDLE,
  }
}

export const loadListUserRole = (options = {}) => {
  return async dispatch => {
    const { cookie } = options
    dispatch(loadListUserRoleRequest())
    try {
      const listRole = await get({
        path: `${API_USER_MANAGEMENT_ROLE}`,
        headers: cookie ? { cookie } : undefined,
      })
      dispatch(loadListUserRoleSuccess(listRole))
    } catch (err) {
      dispatch(loadListUserRoleFailure(err))
    } finally {
      dispatch(loadListUserRoleIdle())
    }
  }
}

export const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST'
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS'
export const UPDATE_USER_FAILURE = 'UPDATE_USER_FAILURE'
export const UPDATE_USER_IDLE = 'UPDATE_USER_IDLE'

export const updateUserRequest = () => {
  return {
    type: UPDATE_USER_REQUEST,
  }
}

export const updateUserSuccess = (userData, oldRoleId) => {
  return {
    type: UPDATE_USER_SUCCESS,
    payload: {
      userData,
      oldRoleId,
    },
  }
}

export const updateUserFailure = error => {
  return {
    type: UPDATE_USER_FAILURE,
    payload: {
      error,
    },
  }
}

export const updateUserIdle = () => {
  return {
    type: UPDATE_USER_IDLE,
  }
}

export const updateUser = (data, userId, oldRoleId) => {
  return async dispatch => {
    dispatch(updateUserRequest())
    try {
      const updatedUser = await patch({
        path: `${API_USER_MANAGEMENT}/${userId}`,
        body: data,
      })
      dispatch(updateUserSuccess(updatedUser, oldRoleId))
    } catch (err) {
      dispatch(updateUserFailure(err))
    } finally {
      dispatch(updateUserIdle())
    }
  }
}

export const DELETE_USER_REQUEST = 'DELETE_USER_REQUEST'
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS'
export const DELETE_USER_FAILURE = 'DELETE_USER_FAILURE'
export const DELETE_USER_IDLE = 'DELETE_USER_IDLE'

export const deleteUserRequest = () => {
  return {
    type: DELETE_USER_REQUEST,
  }
}

export const deleteUserSuccess = userData => {
  return {
    type: DELETE_USER_SUCCESS,
    payload: {
      userData,
    },
  }
}

export const deleteUserFailure = error => {
  return {
    type: DELETE_USER_FAILURE,
    payload: {
      error,
    },
  }
}

export const deleteUserIdle = () => {
  return {
    type: DELETE_USER_IDLE,
  }
}

export const deleteUser = userData => {
  return async dispatch => {
    dispatch(deleteUserRequest())
    try {
      await del({
        path: `${API_USER_MANAGEMENT}/${userData.id}`,
      })
      dispatch(deleteUserSuccess(userData))
    } catch (err) {
      dispatch(deleteUserFailure(err))
    } finally {
      dispatch(deleteUserIdle())
    }
  }
}
