import moment from 'moment'

import {
  socketConnected,
  socketDisconnected,
  socketTrafficDashboard,
  socketInComingVisitor,
  socketUpdateVisitor,
  socketNotification,
  socketIncidenceData,
  socketResponseCheckLicensePlate,
  socketCheckLicensePlateIdle,
  socketResponseSearchListMembers,
  socketSearchListMembersIdle,
  socketResponseSearchListDestination,
  socketSearchListDestinationIdle,
  socketUpdateSystemStatus,
} from '../../../actions/socket'

export const handleSocketConnect = ({ dispatch }) => () => {
  dispatch(socketConnected())
}

export const handleSocketDisconnected = ({ dispatch }) => () => {
  dispatch(socketDisconnected())
}

export const handleSocketTrafficData = ({ dispatch, getState }) => data => {
  const payload = JSON.parse(data)
  const { trafficcontrol } = getState()
  const detectionDate = moment(trafficcontrol.loadTrafficDashboardStatus.date)
  const isSameDay = moment().isSame(detectionDate, 'day')
  if (isSameDay) {
    dispatch(socketTrafficDashboard(payload.data))
  }
}

export const handleSocketDetectNewVisitor = ({ dispatch, getState }) => data => {
  const payload = JSON.parse(data)
  const { visitor } = getState()
  if (payload.cameraId * 1 === visitor.loadListTodayVisitor.camera * 1) {
    dispatch(socketInComingVisitor(payload.data))
  }
}

export const handleSocketUpdateVisitor = ({ dispatch, getState }) => data => {
  const payload = JSON.parse(data)
  const { visitor } = getState()
  if (payload.cameraId * 1 === visitor.loadListTodayVisitor.camera * 1) {
    dispatch(socketUpdateVisitor(payload.data))
  }
}

export const handleSocketNotification = ({ dispatch }) => data => {
  const payload = JSON.parse(data)
  dispatch(socketNotification(payload.data))
}

export const handleSocketIncidenceData = ({ dispatch, getState }) => data => {
  const payload = JSON.parse(data)
  const { listIncidenceYearData, totalIncidenceCount, newIncidenceCase } = payload.data
  const { incidence } = getState()
  const isSameZone = payload.zoneId * 1 === incidence.currentZone * 1
  const detectionDate = moment(incidence.currentDate)
  const isSameDay = moment().isSame(detectionDate, 'day')
  if (isSameZone && isSameDay) {
    dispatch(
      socketIncidenceData({
        listIncidenceYearData,
        totalIncidenceCount,
        newIncidenceCase,
      }),
    )
  }
}

export const handleSocketCheckLicensePlate = ({ dispatch }) => data => {
  const payload = JSON.parse(data)
  dispatch(socketResponseCheckLicensePlate(payload))
  dispatch(socketCheckLicensePlateIdle())
}

export const handleSocketResponeseSearchListDestination = ({ dispatch }) => data => {
  const payload = JSON.parse(data)
  dispatch(socketResponseSearchListDestination(payload))
  dispatch(socketSearchListDestinationIdle())
}

export const handleSocketResponseSearchListMembers = ({ dispatch }) => data => {
  const payload = JSON.parse(data)
  dispatch(socketResponseSearchListMembers(payload))
  dispatch(socketSearchListMembersIdle())
}

export const handleSocketUpdateSystemStatus = ({ dispatch }) => data => {
  const payload = JSON.parse(data)
  dispatch(socketUpdateSystemStatus(payload))
}
