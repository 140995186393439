import queryString from 'query-string'

import { get, ERROR_ABORT_CONTROLLER } from '../../utils'

import {
  API_AVG_TIMESPENT,
  API_GREENGAS,
  API_MAX_GREENGAS,
  API_LATEST_VEHICLE,
  API_VEHICLE_COUNT,
  API_VEHICLE_COUNT_LASTHOUR,
  API_MAX_VEHICLE_COUNT,
  API_VEHICLE_MEMBER,
  API_VEHICLE_PURPOSE,
  API_TRAFFIC_DESTINATION,
  API_TRAFFIC_TYPE,
  API_TRAFFIC_OFFPEAK,
  API_TRAFFIC_ONPEAK,
  API_TRAFFIC_PERHOUR,
  API_TRAFFIC_DESTINATION_RANKING,
} from '../api-constraint'

export const LOAD_TRAFFIC_DASHBOARD_REQUEST = 'LOAD_TRAFFIC_DASHBOARD_REQUEST'
export const LOAD_TRAFFIC_DASHBOARD_SUCCESS = 'LOAD_TRAFFIC_DASHBOARD_SUCCESS'
export const LOAD_TRAFFIC_DASHBOARD_FAILURE = 'LOAD_TRAFFIC_DASHBOARD_FAILURE'
export const LOAD_TRAFFIC_DASHBOARD_IDLE = 'LOAD_TRAFFIC_DASHBOARD_IDLE'

export const loadTrafficDashboardRequest = date => {
  return {
    type: LOAD_TRAFFIC_DASHBOARD_REQUEST,
    payload: {
      date,
    },
  }
}

export const loadTrafficDashboardSuccess = ({
  latestVehicleData,
  vehicleCountNumber,
  maxVehicleCountNumber,
  vehicleCountByTypeList,
  listVehicleType,
  listCompanyContact,
  listPurpose,
  vehicleCountByDestList,
  vehicleCountOnPeakData,
  vehicleCountOffPeakData,
  vehicleCountPerHourList,
  vehicleCountGroupByPurposeData,
  vehicleCountGroupByIsMemberData,
  greenGasValue,
  maxGreenGasValue,
  avgTimeSpentValue,
  listRankCompanyVehicleMember,
  vehicleCountLastHour,
}) => {
  return {
    type: LOAD_TRAFFIC_DASHBOARD_SUCCESS,
    payload: {
      latestVehicleData,
      vehicleCountNumber,
      maxVehicleCountNumber,
      vehicleCountByTypeList,
      listVehicleType,
      listCompanyContact,
      listPurpose,
      vehicleCountByDestList,
      vehicleCountOnPeakData,
      vehicleCountOffPeakData,
      vehicleCountPerHourList,
      vehicleCountGroupByPurposeData,
      vehicleCountGroupByIsMemberData,
      greenGasValue,
      maxGreenGasValue,
      avgTimeSpentValue,
      listRankCompanyVehicleMember,
      vehicleCountLastHour,
    },
  }
}

export const loadTrafficDashboardFailure = error => {
  return {
    type: LOAD_TRAFFIC_DASHBOARD_FAILURE,
    payload: {
      error,
    },
  }
}

export const loadTrafficDashboardIdle = () => {
  return {
    type: LOAD_TRAFFIC_DASHBOARD_IDLE,
  }
}

export const loadTrafficDashboard = (options = {}) => {
  return async dispatch => {
    const { siteId, date, cookie, isPastDate, cameraType, signal } = options
    dispatch(loadTrafficDashboardRequest(date))
    try {
      const query = queryString.stringify({
        date,
        siteId,
        cameraType,
      })

      const queryWithIsPastDate = queryString.stringify({
        date,
        siteId,
        isPastDate,
        cameraType,
      })

      const [
        avgTimeSpentRes,
        greenGasRes,
        greenGasResMax,
        latestVehicleRes,
        vehicleCountRes,
        vehicleCountResMax,
        vehicleCountByDestRes,
        vehicleCountByTypeRes,
        vehicleCountOffPeakRes,
        vehicleCountOnPeakRes,
        vehicleCountPerHourRes,
        vehicleCountPurposeRes,
        vehicleCountMemberRes,
        listRankCompanyVehicleMemberRes,
        vehicleCountLastHourRes,
      ] = await Promise.all([
        get({
          path: `${API_AVG_TIMESPENT}?${query}`,
          headers: cookie ? { cookie } : undefined,
          signal,
        }),
        get({
          path: `${API_GREENGAS}?${query}`,
          headers: cookie ? { cookie } : undefined,
          signal,
        }),
        get({
          path: `${API_MAX_GREENGAS}?${query}`,
          headers: cookie ? { cookie } : undefined,
          signal,
        }),
        get({
          path: `${API_LATEST_VEHICLE}?${query}`,
          headers: cookie ? { cookie } : undefined,
          signal,
        }),
        get({
          path: `${API_VEHICLE_COUNT}?${query}`,
          headers: cookie ? { cookie } : undefined,
          signal,
        }),
        get({
          path: `${API_MAX_VEHICLE_COUNT}?${query}`,
          headers: cookie ? { cookie } : undefined,
          signal,
        }),
        get({
          path: `${API_TRAFFIC_DESTINATION}?${queryWithIsPastDate}`,
          headers: cookie ? { cookie } : undefined,
          signal,
        }),
        get({
          path: `${API_TRAFFIC_TYPE}?${query}`,
          headers: cookie ? { cookie } : undefined,
          signal,
        }),
        get({
          path: `${API_TRAFFIC_OFFPEAK}?${query}`,
          headers: cookie ? { cookie } : undefined,
          signal,
        }),
        get({
          path: `${API_TRAFFIC_ONPEAK}?${query}`,
          headers: cookie ? { cookie } : undefined,
          signal,
        }),
        get({
          path: `${API_TRAFFIC_PERHOUR}?${queryWithIsPastDate}`,
          headers: cookie ? { cookie } : undefined,
          signal,
        }),
        get({
          path: `${API_VEHICLE_PURPOSE}?${query}`,
          headers: cookie ? { cookie } : undefined,
          signal,
        }),
        get({
          path: `${API_VEHICLE_MEMBER}?${query}`,
          headers: cookie ? { cookie } : undefined,
          signal,
        }),
        get({
          path: `${API_TRAFFIC_DESTINATION_RANKING}?${query}`,
          headers: cookie ? { cookie } : undefined,
          signal,
        }),
        get({
          path: `${API_VEHICLE_COUNT_LASTHOUR}?${query}`,
          headers: cookie ? { cookie } : undefined,
          signal,
        }),
      ])

      dispatch(
        loadTrafficDashboardSuccess({
          avgTimeSpentValue: avgTimeSpentRes,
          greenGasValue: greenGasRes.value,
          latestVehicleData: latestVehicleRes.data,
          maxGreenGasValue: greenGasResMax.value,
          vehicleCountNumber: vehicleCountRes.value,
          maxVehicleCountNumber: vehicleCountResMax.value,
          vehicleCountByDestList: vehicleCountByDestRes,
          vehicleCountByTypeList: vehicleCountByTypeRes,
          vehicleCountOffPeakData: vehicleCountOffPeakRes,
          vehicleCountOnPeakData: vehicleCountOnPeakRes,
          vehicleCountPerHourList: vehicleCountPerHourRes,
          vehicleCountGroupByPurposeData: vehicleCountPurposeRes,
          vehicleCountGroupByIsMemberData: vehicleCountMemberRes,
          listRankCompanyVehicleMember: listRankCompanyVehicleMemberRes,
          vehicleCountLastHour: vehicleCountLastHourRes.value,
        }),
      )
      dispatch(loadTrafficDashboardIdle())
    } catch (error) {
      if (error.name === ERROR_ABORT_CONTROLLER) {
        dispatch(loadTrafficDashboardRequest(date))
      } else {
        dispatch(loadTrafficDashboardFailure(error))
        dispatch(loadTrafficDashboardIdle())
      }
    }
  }
}
