import produce from 'immer'
import {
  CHECK_SESSION_REQUEST,
  CHECK_SESSION_SUCCESS,
  CHECK_SESSION_FAILURE,
  CHECK_SESSION_IDLE,
  UPDATE_USER_PROFILE_REQUEST,
  UPDATE_USER_PROFILE_SUCCESS,
  UPDATE_USER_PROFILE_FAILURE,
  UPDATE_USER_PROFILE_IDLE,
} from '../../actions/auth'

import { FETCH_STATUS_IDLE, FETCH_STATUS_REQUEST, FETCH_STATUS_SUCCESS, FETCH_STATUS_FAILURE } from '../../utils'

const initialState = {
  session: undefined,
  checkSession: {
    fetchStatus: FETCH_STATUS_IDLE,
    error: undefined,
  },
  updateUserProfile: {
    fetchStatus: FETCH_STATUS_IDLE,
    error: undefined,
  },
}

const auth = (state = initialState, action) => {
  switch (action.type) {
    case CHECK_SESSION_REQUEST: {
      return produce(state, draftState => {
        draftState.checkSession.fetchStatus = FETCH_STATUS_REQUEST
      })
    }
    case CHECK_SESSION_SUCCESS: {
      return produce(state, draftState => {
        draftState.checkSession.fetchStatus = FETCH_STATUS_SUCCESS
        draftState.session = {
          ...action.payload.session,
          user: {
            ...action.payload.session.user,
            locale: action.payload.session.user.locale.toUpperCase(),
          },
        }
      })
    }
    case CHECK_SESSION_FAILURE: {
      return produce(state, draftState => {
        draftState.session = undefined
        draftState.checkSession.fetchStatus = FETCH_STATUS_FAILURE
        draftState.checkSession.error = action.payload.error
      })
    }
    case CHECK_SESSION_IDLE: {
      return produce(state, draftState => {
        draftState.checkSession.fetchStatus = FETCH_STATUS_IDLE
      })
    }
    case UPDATE_USER_PROFILE_REQUEST: {
      return produce(state, draftState => {
        draftState.updateUserProfile.fetchStatus = FETCH_STATUS_REQUEST
      })
    }
    case UPDATE_USER_PROFILE_SUCCESS: {
      return produce(state, draftState => {
        draftState.updateUserProfile.fetchStatus = FETCH_STATUS_SUCCESS
        draftState.session = action.payload.session
      })
    }
    case UPDATE_USER_PROFILE_FAILURE: {
      return produce(state, draftState => {
        draftState.session = undefined
        draftState.updateUserProfile.fetchStatus = FETCH_STATUS_FAILURE
        draftState.updateUserProfile.error = action.payload.error
      })
    }
    case UPDATE_USER_PROFILE_IDLE: {
      return produce(state, draftState => {
        draftState.updateUserProfile.fetchStatus = FETCH_STATUS_IDLE
      })
    }
    default: {
      return state
    }
  }
}

export default auth
