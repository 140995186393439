import queryString from 'query-string'

import { get, patch, post, del } from '../../utils'
import { API_LIST_MEMBER, API_DESTINATION, API_MEMBER, API_LIST_PLATE_ORIGIN } from '../api-constraint'

export const LOAD_LIST_MEMBER_REQUEST = 'LOAD_LIST_MEMBER_REQUEST'
export const LOAD_LIST_MEMBER_SUCCESS = 'LOAD_LIST_MEMBER_SUCCESS'
export const LOAD_LIST_MEMBER_FAILURE = 'LOAD_LIST_MEMBER_FAILURE'
export const LOAD_LIST_MEMBER_IDLE = 'LOAD_LIST_MEMBER_IDLE'

export const loadListMemberRequest = () => {
  return {
    type: LOAD_LIST_MEMBER_REQUEST,
  }
}

export const loadListMemberSuccess = ({ totalItems, totalPages, page, data }) => {
  return {
    type: LOAD_LIST_MEMBER_SUCCESS,
    payload: {
      totalItems,
      totalPages,
      page,
      data,
    },
  }
}

export const loadListMemberFailure = error => {
  return {
    type: LOAD_LIST_MEMBER_FAILURE,
    payload: {
      error,
    },
  }
}

export const loadListMemberIdle = () => {
  return {
    type: LOAD_LIST_MEMBER_IDLE,
  }
}

export const loadListMember = (options = {}) => {
  return async dispatch => {
    const { cookie, limit } = options
    const query = queryString.stringify({
      limit,
    })
    dispatch(loadListMemberRequest())
    try {
      const response = await get({
        path: `${API_LIST_MEMBER}?${query}`,
        headers: cookie ? { cookie } : undefined,
      })
      dispatch(loadListMemberSuccess(response))
    } catch (err) {
      dispatch(loadListMemberFailure(err))
    } finally {
      dispatch(loadListMemberIdle())
    }
  }
}

export const LOAD_LIST_PROVINCE_REQUEST = 'LOAD_LIST_PROVINCE_REQUEST'
export const LOAD_LIST_PROVINCE_SUCCESS = 'LOAD_LIST_PROVINCE_SUCCESS'
export const LOAD_LIST_PROVINCE_FAILURE = 'LOAD_LIST_PROVINCE_FAILURE'
export const LOAD_LIST_PROVINCE_IDLE = 'LOAD_LIST_PROVINCE_IDLE'

export const loadListProvinceRequest = () => {
  return {
    type: LOAD_LIST_PROVINCE_REQUEST,
  }
}

export const loadListProvinceSuccess = listProvince => {
  return {
    type: LOAD_LIST_PROVINCE_SUCCESS,
    payload: {
      listProvince,
    },
  }
}

export const loadListProvinceFailure = error => {
  return {
    type: LOAD_LIST_PROVINCE_FAILURE,
    payload: {
      error,
    },
  }
}

export const loadListProvinceIdle = () => {
  return {
    type: LOAD_LIST_PROVINCE_IDLE,
  }
}

export const loadListProvince = (options = {}) => {
  return async dispatch => {
    const { cookie } = options
    dispatch(loadListProvinceRequest())
    try {
      const listProvince = await get({
        path: `${API_LIST_PLATE_ORIGIN}`,
        headers: cookie ? { cookie } : undefined,
      })
      dispatch(loadListProvinceSuccess(listProvince))
    } catch (err) {
      dispatch(loadListProvinceFailure(err))
    } finally {
      dispatch(loadListProvinceIdle())
    }
  }
}

export const LOAD_LIST_COMPANY_REQUEST = 'LOAD_LIST_COMPANY_REQUEST'
export const LOAD_LIST_COMPANY_SUCCESS = 'LOAD_LIST_COMPANY_SUCCESS'
export const LOAD_LIST_COMPANY_FAILURE = 'LOAD_LIST_COMPANY_FAILURE'
export const LOAD_LIST_COMPANY_IDLE = 'LOAD_LIST_COMPANY_IDLE'

export const loadListCompanyRequest = () => {
  return {
    type: LOAD_LIST_COMPANY_REQUEST,
  }
}

export const loadListCompanySuccess = listDestination => {
  return {
    type: LOAD_LIST_COMPANY_SUCCESS,
    payload: {
      listDestination,
    },
  }
}

export const loadListCompanyFailure = error => {
  return {
    type: LOAD_LIST_COMPANY_FAILURE,
    payload: {
      error,
    },
  }
}

export const loadListCompanyIdle = () => {
  return {
    type: LOAD_LIST_COMPANY_IDLE,
  }
}

export const loadListCompany = (options = {}) => {
  return async dispatch => {
    const { cookie } = options
    dispatch(loadListCompanyRequest())
    try {
      const listDestination = await get({
        path: `${API_DESTINATION}`,
        headers: cookie ? { cookie } : undefined,
      })
      dispatch(loadListCompanySuccess(listDestination))
    } catch (err) {
      dispatch(loadListCompanyFailure(err))
    } finally {
      dispatch(loadListCompanyIdle())
    }
  }
}

export const LOAD_MEMBER_REQUEST = 'LOAD_MEMBER_REQUEST'
export const LOAD_MEMBER_SUCCESS = 'LOAD_MEMBER_SUCCESS'
export const LOAD_MEMBER_FAILURE = 'LOAD_MEMBER_FAILURE'
export const LOAD_MEMBER_IDLE = 'LOAD_MEMBER_IDLE'

export const loadMemberRequest = () => {
  return {
    type: LOAD_MEMBER_REQUEST,
  }
}

export const loadMemberSuccess = memberData => {
  return {
    type: LOAD_MEMBER_SUCCESS,
    payload: {
      memberData,
    },
  }
}

export const loadMemberFailure = error => {
  return {
    type: LOAD_MEMBER_FAILURE,
    payload: {
      error,
    },
  }
}

export const loadMemberIdle = () => {
  return {
    type: LOAD_MEMBER_IDLE,
  }
}

export const loadMember = (options = {}) => {
  return async dispatch => {
    const { cookie, id } = options
    dispatch(loadMemberRequest())
    try {
      const destiantionData = await get({
        path: `${API_MEMBER}/${id}`,
        headers: cookie ? { cookie } : undefined,
      })
      dispatch(loadMemberSuccess(destiantionData))
    } catch (err) {
      dispatch(loadMemberFailure(err))
    } finally {
      dispatch(loadMemberIdle())
    }
  }
}

export const CREATE_MEMBER_REQUEST = 'CREATE_MEMBER_REQUEST'
export const CREATE_MEMBER_SUCCESS = 'CREATE_MEMBER_SUCCESS'
export const CREATE_MEMBER_FAILURE = 'CREATE_MEMBER_FAILURE'
export const CREATE_MEMBER_IDLE = 'CREATE_MEMBER_IDLE'

export const createMemberRequest = () => {
  return {
    type: CREATE_MEMBER_REQUEST,
  }
}

export const createMemberSuccess = memberData => {
  return {
    type: CREATE_MEMBER_SUCCESS,
    payload: {
      memberData,
    },
  }
}

export const createMemberFailure = error => {
  return {
    type: CREATE_MEMBER_FAILURE,
    payload: {
      error,
    },
  }
}

export const createMemberIdle = () => {
  return {
    type: CREATE_MEMBER_IDLE,
  }
}

export const createMember = data => {
  return async dispatch => {
    dispatch(createMemberRequest())
    try {
      const memberData = await post({
        path: `${API_MEMBER}`,
        body: data,
      })
      dispatch(createMemberSuccess(memberData))
    } catch (err) {
      dispatch(createMemberFailure(err))
    } finally {
      dispatch(createMemberIdle())
    }
  }
}

export const UPDATE_MEMBER_REQUEST = 'UPDATE_MEMBER_REQUEST'
export const UPDATE_MEMBER_SUCCESS = 'UPDATE_MEMBER_SUCCESS'
export const UPDATE_MEMBER_FAILURE = 'UPDATE_MEMBER_FAILURE'
export const UPDATE_MEMBER_IDLE = 'UPDATE_MEMBER_IDLE'

export const updateMemberRequest = () => {
  return {
    type: UPDATE_MEMBER_REQUEST,
  }
}

export const updateMemberSuccess = memberData => {
  return {
    type: UPDATE_MEMBER_SUCCESS,
    payload: {
      memberData,
    },
  }
}

export const updateMemberFailure = error => {
  return {
    type: UPDATE_MEMBER_FAILURE,
    payload: {
      error,
    },
  }
}

export const updateMemberIdle = () => {
  return {
    type: UPDATE_MEMBER_IDLE,
  }
}

export const updateMember = data => {
  return async dispatch => {
    dispatch(updateMemberRequest())
    try {
      const updatedMemberData = await patch({
        path: `${API_MEMBER}/${data.id}`,
        body: data,
      })
      dispatch(updateMemberSuccess(updatedMemberData))
    } catch (err) {
      dispatch(updateMemberFailure(err))
    } finally {
      dispatch(updateMemberIdle())
    }
  }
}

export const DELETE_MEMBER_REQUEST = 'DELETE_MEMBER_REQUEST'
export const DELETE_MEMBER_SUCCESS = 'DELETE_MEMBER_SUCCESS'
export const DELETE_MEMBER_FAILURE = 'DELETE_MEMBER_FAILURE'
export const DELETE_MEMBER_IDLE = 'DELETE_MEMBER_IDLE'

export const deleteMemberRequest = () => {
  return {
    type: DELETE_MEMBER_REQUEST,
  }
}

export const deleteMemberSuccess = () => {
  return {
    type: DELETE_MEMBER_SUCCESS,
  }
}

export const deleteMemberFailure = error => {
  return {
    type: DELETE_MEMBER_FAILURE,
    payload: {
      error,
    },
  }
}

export const deleteMemberIdle = () => {
  return {
    type: DELETE_MEMBER_IDLE,
  }
}

export const deleteMember = id => {
  return async dispatch => {
    dispatch(deleteMemberRequest())
    try {
      await del({
        path: `${API_MEMBER}/${id}`,
      })
      dispatch(deleteMemberSuccess())
    } catch (err) {
      dispatch(deleteMemberFailure(err))
    } finally {
      dispatch(deleteMemberIdle())
    }
  }
}
