import * as Sentry from '@sentry/node'
import { RewriteFrames } from '@sentry/integrations'
import getConfig from 'next/config'

import { ERROR_MESSAGE_STOP_ROUTER_CHANGE } from '../index'

const config = getConfig() || {}
const publicRuntimeConfig = {
  SENTRY_DSN: process.env.SENTRY_DSN,
  SENTRY_ENVIRONMENT: process.env.SENTRY_ENVIRONMENT,
  GIT_COMMIT_ID: process.env.GIT_COMMIT_ID,
  ...config.publicRuntimeConfig,
}

function beforeSend(event, hint) {
  const error = hint.originalException
  if (error === ERROR_MESSAGE_STOP_ROUTER_CHANGE) {
    return null
  }

  return event
}

export const initializeSentry = () => {
  if (publicRuntimeConfig.SENTRY_DSN) {
    const integrations = []
    integrations.push(
      new RewriteFrames({
        iteratee: frame => {
          frame.filename = frame.filename.replace('.next', '_next')
          return frame
        },
      }),
    )

    Sentry.init({
      enabled: process.env.NODE_ENV === 'production',
      dsn: publicRuntimeConfig.SENTRY_DSN,
      environment: publicRuntimeConfig.SENTRY_ENVIRONMENT,
      release: publicRuntimeConfig.GIT_COMMIT_ID,
      integrations,
      tracesSampleRate: 1.0,
      normalizeDepth: 10,
      beforeSend,
    })
  }
}
