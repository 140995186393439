import produce from 'immer'

import {
  LOAD_SYSTEM_HEALTH_REQUEST,
  LOAD_SYSTEM_HEALTH_SUCCESS,
  LOAD_SYSTEM_HEALTH_FAILURE,
  LOAD_SYSTEM_HEALTH_IDLE,
} from '../../actions/system-health'
import { SOCKET_UPDATE_SYSTEM_STATUS } from '../../actions/socket'

import { FETCH_STATUS_REQUEST, FETCH_STATUS_SUCCESS, FETCH_STATUS_FAILURE, FETCH_STATUS_IDLE } from '../../utils'

const initialState = {
  systemHealthData: undefined,
  loadSystemHealth: {
    fetchStatus: FETCH_STATUS_IDLE,
    error: undefined,
  },
}

const systemHealth = (state = initialState, action) => {
  const { type } = action
  switch (type) {
    case LOAD_SYSTEM_HEALTH_REQUEST: {
      return produce(state, draftState => {
        draftState.loadSystemHealth.fetchStatus = FETCH_STATUS_REQUEST
      })
    }
    case LOAD_SYSTEM_HEALTH_SUCCESS: {
      return produce(state, draftState => {
        draftState.loadSystemHealth.fetchStatus = FETCH_STATUS_SUCCESS
        draftState.systemHealthData = action.payload.systemHealth
      })
    }
    case LOAD_SYSTEM_HEALTH_FAILURE: {
      return produce(state, draftState => {
        draftState.loadSystemHealth.fetchStatus = FETCH_STATUS_FAILURE
        draftState.loadSystemHealth.error = action.payload.error
      })
    }
    case LOAD_SYSTEM_HEALTH_IDLE: {
      return produce(state, draftState => {
        draftState.loadSystemHealth.fetchStatus = FETCH_STATUS_IDLE
      })
    }
    case SOCKET_UPDATE_SYSTEM_STATUS: {
      return produce(state, draftState => {
        draftState.systemHealthData = action.payload.systemHealth
      })
    }
    default: {
      return state
    }
  }
}

export default systemHealth
