import io from 'socket.io-client'

import {
  CONNECT_SOCKET,
  SOCKET_TRAFFIC_DASHBOARD,
  SOCKET_NOTIFICATION,
  SOCKET_IN_COMING_VISITOR,
  SOCKET_UPDATE_VISITOR,
  SOCKET_INCIDENCE_DATA,
  SOCKET_REQUEST_CHECK_LICENSE_PLATE,
  SOCKET_RESPONSE_CHECK_LICENSE_PLATE,
  SOCKET_REQUEST_SEARCH_LIST_MEMBERS,
  SOCKET_RESPONSE_SEARCH_LIST_MEMBERS,
  SOCKET_REQUEST_SEARCH_LIST_DESTINATION,
  SOCKET_RESPONSE_SEARCH_LIST_DESTINATION,
  SOCKET_UPDATE_SYSTEM_STATUS,
} from '../../../actions/socket'

import {
  handleSocketConnect,
  handleSocketDisconnected,
  handleSocketTrafficData,
  handleSocketDetectNewVisitor,
  handleSocketUpdateVisitor,
  handleSocketNotification,
  handleSocketIncidenceData,
  handleSocketCheckLicensePlate,
  handleSocketResponseSearchListMembers,
  handleSocketResponeseSearchListDestination,
  handleSocketUpdateSystemStatus,
} from './handler'

const createSocketMiddleware = () => {
  let socket
  return store => next => action => {
    switch (action.type) {
      case CONNECT_SOCKET: {
        if (socket) {
          socket.disconnect()
        }
        socket = io({
          transports: ['websocket', 'polling'],
          secure: true,
        })
        socket.on('connect', handleSocketConnect(store))
        socket.on('disconnect', handleSocketDisconnected(store))
        socket.on(SOCKET_TRAFFIC_DASHBOARD, handleSocketTrafficData(store))
        socket.on(SOCKET_IN_COMING_VISITOR, handleSocketDetectNewVisitor(store))
        socket.on(SOCKET_UPDATE_VISITOR, handleSocketUpdateVisitor(store))
        socket.on(SOCKET_NOTIFICATION, handleSocketNotification(store))
        socket.on(SOCKET_INCIDENCE_DATA, handleSocketIncidenceData(store))
        socket.on(SOCKET_RESPONSE_CHECK_LICENSE_PLATE, handleSocketCheckLicensePlate(store))
        socket.on(SOCKET_RESPONSE_SEARCH_LIST_MEMBERS, handleSocketResponseSearchListMembers(store))
        socket.on(SOCKET_RESPONSE_SEARCH_LIST_DESTINATION, handleSocketResponeseSearchListDestination(store))
        socket.on(SOCKET_UPDATE_SYSTEM_STATUS, handleSocketUpdateSystemStatus(store))
        return next(action)
      }
      case SOCKET_REQUEST_CHECK_LICENSE_PLATE: {
        socket.emit(SOCKET_REQUEST_CHECK_LICENSE_PLATE, JSON.stringify(action.payload))
        return next(action)
      }
      case SOCKET_REQUEST_SEARCH_LIST_MEMBERS: {
        socket.emit(SOCKET_REQUEST_SEARCH_LIST_MEMBERS, JSON.stringify(action.payload))
        return next(action)
      }
      case SOCKET_REQUEST_SEARCH_LIST_DESTINATION: {
        socket.emit(SOCKET_REQUEST_SEARCH_LIST_DESTINATION, JSON.stringify(action.payload))
        return next(action)
      }
      default: {
        return next(action)
      }
    }
  }
}

export default createSocketMiddleware
