import produce from 'immer'
import moment from 'moment'

import {
  LOAD_LIST_NOTIFICATION_REQUEST,
  LOAD_LIST_NOTIFICATION_SUCCESS,
  LOAD_LIST_NOTIFICATION_FAILURE,
  LOAD_LIST_NOTIFICATION_IDLE,
} from '../../actions/application'

import { SOCKET_NOTIFICATION } from '../../actions/socket'

import { FETCH_STATUS_REQUEST, FETCH_STATUS_SUCCESS, FETCH_STATUS_FAILURE, FETCH_STATUS_IDLE } from '../../utils'

const initialState = {
  listNotification: [],
  loadListNotification: {
    fetchStatus: FETCH_STATUS_IDLE,
    error: undefined,
  },
}

const application = (state = initialState, action) => {
  const { type } = action
  switch (type) {
    case LOAD_LIST_NOTIFICATION_REQUEST: {
      return produce(state, draftState => {
        draftState.loadListNotification.fetchStatus = FETCH_STATUS_REQUEST
      })
    }
    case LOAD_LIST_NOTIFICATION_SUCCESS: {
      return produce(state, draftState => {
        draftState.loadListNotification.fetchStatus = FETCH_STATUS_SUCCESS
        draftState.listNotification = action.payload.listNotification.sort(
          (a, b) => moment(b.metadata.timestamp).toDate() - moment(a.metadata.timestamp).toDate(),
        )
      })
    }
    case LOAD_LIST_NOTIFICATION_FAILURE: {
      return produce(state, draftState => {
        draftState.loadListNotification.fetchStatus = FETCH_STATUS_FAILURE
        draftState.loadListNotification.error = action.payload.error
      })
    }
    case LOAD_LIST_NOTIFICATION_IDLE: {
      return produce(state, draftState => {
        draftState.loadListNotification.fetchStatus = FETCH_STATUS_IDLE
      })
    }
    case SOCKET_NOTIFICATION: {
      return produce(state, draftState => {
        draftState.listNotification = [action.payload.notificationData, ...state.listNotification]
      })
    }
    default: {
      return state
    }
  }
}

export default application
