import produce from 'immer'
import {
  LOAD_LIST_USER_REQUEST,
  LOAD_LIST_USER_SUCCESS,
  LOAD_LIST_USER_FAILURE,
  LOAD_LIST_USER_IDLE,
  REGISTER_USER_REQUEST,
  REGISTER_USER_SUCCESS,
  REGISTER_USER_FAILURE,
  REGISTER_USER_IDLE,
  LOAD_LIST_USER_ROLE_REQUEST,
  LOAD_LIST_USER_ROLE_SUCCESS,
  LOAD_LIST_USER_ROLE_FAILURE,
  LOAD_LIST_USER_ROLE_IDLE,
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAILURE,
  UPDATE_USER_IDLE,
  DELETE_USER_REQUEST,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAILURE,
  DELETE_USER_IDLE,
} from '../../actions/user-management'

import { FETCH_STATUS_IDLE, FETCH_STATUS_REQUEST, FETCH_STATUS_SUCCESS, FETCH_STATUS_FAILURE } from '../../utils'

const initialState = {
  listRoleUser: [],
  listRole: [],
  loadListUser: {
    fetchStatus: FETCH_STATUS_IDLE,
    error: undefined,
  },
  loadListUserRole: {
    fetchStatus: FETCH_STATUS_IDLE,
    error: undefined,
  },
  registerUser: {
    fetchStatus: FETCH_STATUS_IDLE,
    error: undefined,
    userData: undefined,
  },
  updateUser: {
    fetchStatus: FETCH_STATUS_IDLE,
    error: undefined,
  },
  deleteUser: {
    fetchStatus: FETCH_STATUS_IDLE,
    error: undefined,
  },
}

const userManagement = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_LIST_USER_REQUEST: {
      return produce(state, draftState => {
        draftState.loadListUser.fetchStatus = FETCH_STATUS_REQUEST
      })
    }
    case LOAD_LIST_USER_SUCCESS: {
      return produce(state, draftState => {
        draftState.loadListUser.fetchStatus = FETCH_STATUS_SUCCESS
        draftState.listRoleUser = action.payload.listRoleUser
      })
    }
    case LOAD_LIST_USER_FAILURE: {
      return produce(state, draftState => {
        draftState.loadListUser.fetchStatus = FETCH_STATUS_FAILURE
        draftState.loadListUser.error = action.payload.error
      })
    }
    case LOAD_LIST_USER_IDLE: {
      return produce(state, draftState => {
        draftState.loadListUser.fetchStatus = FETCH_STATUS_IDLE
      })
    }
    case REGISTER_USER_REQUEST: {
      return produce(state, draftState => {
        draftState.registerUser.fetchStatus = FETCH_STATUS_REQUEST
        draftState.registerUser.userData = action.payload.userData
        draftState.registerUser.error = undefined
      })
    }
    case REGISTER_USER_SUCCESS: {
      const preparedListRoleUser = state.listRoleUser.map(roleUserData => {
        if (roleUserData.id === action.payload.userData.roleId) {
          return { ...roleUserData, users: [...roleUserData.users, action.payload.userData] }
        }
        return roleUserData
      })
      return produce(state, draftState => {
        draftState.registerUser.fetchStatus = FETCH_STATUS_SUCCESS
        draftState.registerUser.error = undefined
        draftState.listRoleUser = preparedListRoleUser
      })
    }
    case REGISTER_USER_FAILURE: {
      return produce(state, draftState => {
        draftState.registerUser.fetchStatus = FETCH_STATUS_FAILURE
        draftState.registerUser.error = action.payload.error
      })
    }
    case REGISTER_USER_IDLE: {
      return produce(state, draftState => {
        draftState.registerUser.fetchStatus = FETCH_STATUS_IDLE
      })
    }
    case LOAD_LIST_USER_ROLE_REQUEST: {
      return produce(state, draftState => {
        draftState.loadListUserRole.fetchStatus = FETCH_STATUS_REQUEST
      })
    }
    case LOAD_LIST_USER_ROLE_SUCCESS: {
      return produce(state, draftState => {
        draftState.loadListUserRole.fetchStatus = FETCH_STATUS_SUCCESS
        draftState.listRole = action.payload.listRole.map(roleData => {
          return {
            text: roleData.name,
            value: roleData.id,
          }
        })
      })
    }
    case LOAD_LIST_USER_ROLE_FAILURE: {
      return produce(state, draftState => {
        draftState.loadListUserRole.fetchStatus = FETCH_STATUS_FAILURE
        draftState.loadListUserRole.error = action.payload.error
      })
    }
    case LOAD_LIST_USER_ROLE_IDLE: {
      return produce(state, draftState => {
        draftState.loadListUserRole.fetchStatus = FETCH_STATUS_IDLE
      })
    }
    case UPDATE_USER_REQUEST: {
      return produce(state, draftState => {
        draftState.updateUser.fetchStatus = FETCH_STATUS_REQUEST
      })
    }
    case UPDATE_USER_SUCCESS: {
      const preparedListRoleUser = state.listRoleUser.map(roleUserData => {
        let prepareData = { ...roleUserData }
        if (roleUserData.id === action.payload.oldRoleId) {
          prepareData = { ...prepareData, users: prepareData.users.filter(userData => userData.id !== action.payload.userData.id) }
        }
        if (roleUserData.id === action.payload.userData.roleId) {
          prepareData = { ...prepareData, users: [...prepareData.users, action.payload.userData] }
        }
        return prepareData
      })
      return produce(state, draftState => {
        draftState.updateUser.fetchStatus = FETCH_STATUS_SUCCESS
        draftState.listRoleUser = preparedListRoleUser
      })
    }
    case UPDATE_USER_FAILURE: {
      return produce(state, draftState => {
        draftState.updateUser.fetchStatus = FETCH_STATUS_FAILURE
        draftState.updateUser.error = action.payload.error
      })
    }
    case UPDATE_USER_IDLE: {
      return produce(state, draftState => {
        draftState.updateUser.fetchStatus = FETCH_STATUS_IDLE
      })
    }
    case DELETE_USER_REQUEST: {
      return produce(state, draftState => {
        draftState.deleteUser.fetchStatus = FETCH_STATUS_REQUEST
      })
    }
    case DELETE_USER_SUCCESS: {
      const preparedListRoleUser = state.listRoleUser.map(roleUserData => {
        if (roleUserData.id === action.payload.userData.roleId) {
          return { ...roleUserData, users: roleUserData.users.filter(userData => userData.id !== action.payload.userData.id) }
        }
        return roleUserData
      })
      return produce(state, draftState => {
        draftState.deleteUser.fetchStatus = FETCH_STATUS_SUCCESS
        draftState.listRoleUser = preparedListRoleUser
      })
    }
    case DELETE_USER_FAILURE: {
      return produce(state, draftState => {
        draftState.deleteUser.fetchStatus = FETCH_STATUS_FAILURE
        draftState.deleteUser.error = action.payload.error
      })
    }
    case DELETE_USER_IDLE: {
      return produce(state, draftState => {
        draftState.deleteUser.fetchStatus = FETCH_STATUS_IDLE
      })
    }
    default: {
      return state
    }
  }
}

export default userManagement
