import produce from 'immer'

import { FETCH_STATUS_IDLE, FETCH_STATUS_REQUEST, FETCH_STATUS_SUCCESS, FETCH_STATUS_FAILURE } from '../../utils'
import {
  LOAD_LIST_ZONE_REQUEST,
  LOAD_LIST_ZONE_SUCCESS,
  LOAD_LIST_ZONE_FAILURE,
  LOAD_LIST_ZONE_IDLE,
  LOAD_TENANT_REQUEST,
  LOAD_TENANT_SUCCESS,
  LOAD_TENANT_FAILURE,
  LOAD_TENANT_IDLE,
  CREATE_TENANT_REQUEST,
  CREATE_TENANT_SUCCESS,
  CREATE_TENANT_FAILURE,
  CREATE_TENANT_IDLE,
  UPDATE_TENANT_REQUEST,
  UPDATE_TENANT_SUCCESS,
  UPDATE_TENANT_FAILURE,
  UPDATE_TENANT_IDLE,
  DELETE_TENANT_REQUEST,
  DELETE_TENANT_SUCCESS,
  DELETE_TENANT_FAILURE,
  DELETE_TENANT_IDLE,
  LOAD_LIST_TENANT_REQUEST,
  LOAD_LIST_TENANT_SUCCESS,
  LOAD_LIST_TENANT_FAILURE,
  LOAD_LIST_TENANT_IDLE,
  LOAD_LIST_MEMBER_IN_TENANT_REQUEST,
  LOAD_LIST_MEMBER_IN_TENANT_SUCCESS,
  LOAD_LIST_MEMBER_IN_TENANT_FAILURE,
  LOAD_LIST_MEMBER_IN_TENANT_IDLE,
} from '../../actions/tenant'

import { SOCKET_RESPONSE_SEARCH_LIST_DESTINATION } from '../../actions/socket'

const initialState = {
  listZone: [],
  listTenant: [],
  totalItems: 0,
  totalPages: 1,
  page: 1,
  tenantData: undefined,
  loadListTenant: {
    fetchStatus: FETCH_STATUS_IDLE,
    error: undefined,
  },
  loadListZone: {
    fetchStatus: FETCH_STATUS_IDLE,
    error: undefined,
  },
  loadTenant: {
    fetchStatus: FETCH_STATUS_IDLE,
    error: undefined,
  },
  createTenant: {
    fetchStatus: FETCH_STATUS_IDLE,
    error: undefined,
  },
  updateTenant: {
    fetchStatus: FETCH_STATUS_IDLE,
    error: undefined,
  },
  deleteTenant: {
    fetchStatus: FETCH_STATUS_IDLE,
    error: undefined,
  },
  loadListMemberInTenant: {
    id: undefined,
    listMember: [],
    fetchStatus: FETCH_STATUS_IDLE,
    error: undefined,
  },
}

const tenant = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_LIST_TENANT_REQUEST: {
      return produce(state, draftState => {
        draftState.loadListTenant.fetchStatus = FETCH_STATUS_REQUEST
      })
    }
    case LOAD_LIST_TENANT_SUCCESS: {
      return produce(state, draftState => {
        draftState.loadListTenant.fetchStatus = FETCH_STATUS_SUCCESS
        draftState.listTenant = action.payload.data
        draftState.totalItems = action.payload.totalItems
        draftState.totalPages = action.payload.totalPages
        draftState.page = action.payload.page
      })
    }
    case LOAD_LIST_TENANT_FAILURE: {
      return produce(state, draftState => {
        draftState.loadListTenant.fetchStatus = FETCH_STATUS_FAILURE
        draftState.loadListTenant.error = action.payload.error
      })
    }
    case LOAD_LIST_TENANT_IDLE: {
      return produce(state, draftState => {
        draftState.loadListTenant.fetchStatus = FETCH_STATUS_IDLE
      })
    }
    case SOCKET_RESPONSE_SEARCH_LIST_DESTINATION: {
      return produce(state, draftState => {
        draftState.listTenant = action.payload.data
        draftState.totalItems = action.payload.totalItems
        draftState.totalPages = action.payload.totalPages
        draftState.page = action.payload.page
      })
    }
    case LOAD_LIST_ZONE_REQUEST: {
      return produce(state, draftState => {
        draftState.loadListZone.fetchStatus = FETCH_STATUS_REQUEST
      })
    }
    case LOAD_LIST_ZONE_SUCCESS: {
      return produce(state, draftState => {
        draftState.loadListZone.fetchStatus = FETCH_STATUS_SUCCESS
        draftState.listZone = action.payload.listZone.map(zoneData => ({ ...zoneData, text: zoneData.name, value: zoneData.id }))
      })
    }
    case LOAD_LIST_ZONE_FAILURE: {
      return produce(state, draftState => {
        draftState.loadListZone.fetchStatus = FETCH_STATUS_FAILURE
        draftState.loadListZone.error = action.payload.error
      })
    }
    case LOAD_LIST_ZONE_IDLE: {
      return produce(state, draftState => {
        draftState.loadListZone.fetchStatus = FETCH_STATUS_IDLE
      })
    }

    case LOAD_TENANT_REQUEST: {
      return produce(state, draftState => {
        draftState.loadTenant.fetchStatus = FETCH_STATUS_REQUEST
      })
    }
    case LOAD_TENANT_SUCCESS: {
      return produce(state, draftState => {
        draftState.loadTenant.fetchStatus = FETCH_STATUS_SUCCESS
        draftState.tenantData = action.payload.tenantData
      })
    }
    case LOAD_TENANT_FAILURE: {
      return produce(state, draftState => {
        draftState.loadTenant.fetchStatus = FETCH_STATUS_FAILURE
        draftState.loadTenant.error = action.payload.error
      })
    }
    case LOAD_TENANT_IDLE: {
      return produce(state, draftState => {
        draftState.loadTenant.fetchStatus = FETCH_STATUS_IDLE
      })
    }
    case CREATE_TENANT_REQUEST: {
      return produce(state, draftState => {
        draftState.createTenant.fetchStatus = FETCH_STATUS_REQUEST
      })
    }
    case CREATE_TENANT_SUCCESS: {
      return produce(state, draftState => {
        draftState.createTenant.fetchStatus = FETCH_STATUS_SUCCESS
      })
    }
    case CREATE_TENANT_FAILURE: {
      return produce(state, draftState => {
        draftState.createTenant.fetchStatus = FETCH_STATUS_FAILURE
        draftState.createTenant.error = action.payload.error
      })
    }
    case CREATE_TENANT_IDLE: {
      return produce(state, draftState => {
        draftState.createTenant.fetchStatus = FETCH_STATUS_IDLE
      })
    }
    case UPDATE_TENANT_REQUEST: {
      return produce(state, draftState => {
        draftState.updateTenant.fetchStatus = FETCH_STATUS_REQUEST
      })
    }
    case UPDATE_TENANT_SUCCESS: {
      return produce(state, draftState => {
        draftState.updateTenant.fetchStatus = FETCH_STATUS_SUCCESS
        draftState.tenantData = action.payload.tenantData
      })
    }
    case UPDATE_TENANT_FAILURE: {
      return produce(state, draftState => {
        draftState.updateTenant.fetchStatus = FETCH_STATUS_FAILURE
        draftState.updateTenant.error = action.payload.error
      })
    }
    case UPDATE_TENANT_IDLE: {
      return produce(state, draftState => {
        draftState.updateTenant.fetchStatus = FETCH_STATUS_IDLE
      })
    }
    case DELETE_TENANT_REQUEST: {
      return produce(state, draftState => {
        draftState.deleteTenant.fetchStatus = FETCH_STATUS_REQUEST
      })
    }
    case DELETE_TENANT_SUCCESS: {
      return produce(state, draftState => {
        draftState.deleteTenant.fetchStatus = FETCH_STATUS_SUCCESS
      })
    }
    case DELETE_TENANT_FAILURE: {
      return produce(state, draftState => {
        draftState.deleteTenant.fetchStatus = FETCH_STATUS_FAILURE
        draftState.deleteTenant.error = action.payload.error
      })
    }
    case DELETE_TENANT_IDLE: {
      return produce(state, draftState => {
        draftState.deleteTenant.fetchStatus = FETCH_STATUS_IDLE
      })
    }
    case LOAD_LIST_MEMBER_IN_TENANT_REQUEST: {
      return produce(state, draftState => {
        draftState.loadListMemberInTenant.fetchStatus = FETCH_STATUS_REQUEST
        draftState.loadListMemberInTenant.id = action.payload.id
        draftState.loadListMemberInTenant.listMember = []
      })
    }
    case LOAD_LIST_MEMBER_IN_TENANT_SUCCESS: {
      return produce(state, draftState => {
        draftState.loadListMemberInTenant.fetchStatus = FETCH_STATUS_SUCCESS
        draftState.loadListMemberInTenant.listMember = action.payload.listMember
      })
    }
    case LOAD_LIST_MEMBER_IN_TENANT_FAILURE: {
      return produce(state, draftState => {
        draftState.loadListMemberInTenant.fetchStatus = FETCH_STATUS_FAILURE
        draftState.loadListMemberInTenant.error = action.payload.error
      })
    }
    case LOAD_LIST_MEMBER_IN_TENANT_IDLE: {
      return produce(state, draftState => {
        draftState.loadListMemberInTenant.fetchStatus = FETCH_STATUS_IDLE
      })
    }
    default: {
      return state
    }
  }
}

export default tenant
