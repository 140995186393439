import { get } from '../../utils'
import { API_SYSTEM_STATUS } from '../api-constraint'

export const LOAD_SYSTEM_HEALTH_REQUEST = 'LOAD_SYSTEM_HEALTH_REQUEST'
export const LOAD_SYSTEM_HEALTH_SUCCESS = 'LOAD_SYSTEM_HEALTH_SUCCESS'
export const LOAD_SYSTEM_HEALTH_FAILURE = 'LOAD_SYSTEM_HEALTH_FAILURE'
export const LOAD_SYSTEM_HEALTH_IDLE = 'LOAD_SYSTEM_HEALTH_IDLE'

export const loadSystemHealthRequest = () => {
  return {
    type: LOAD_SYSTEM_HEALTH_REQUEST,
  }
}

export const loadSystemHealthSuccess = systemHealth => {
  return {
    type: LOAD_SYSTEM_HEALTH_SUCCESS,
    payload: {
      systemHealth,
    },
  }
}

export const loadSystemHealthFailure = error => {
  return {
    type: LOAD_SYSTEM_HEALTH_FAILURE,
    payload: {
      error,
    },
  }
}

export const loadSystemHealthIdle = () => {
  return {
    type: LOAD_SYSTEM_HEALTH_IDLE,
  }
}

export const loadSystemHealth = (options = {}) => {
  return async dispatch => {
    const { cookie } = options
    dispatch(loadSystemHealthRequest())
    try {
      const systemHealth = await get({
        path: API_SYSTEM_STATUS,
        headers: cookie ? { cookie } : undefined,
      })
      dispatch(loadSystemHealthSuccess(systemHealth))
    } catch (err) {
      dispatch(loadSystemHealthFailure(err))
    } finally {
      dispatch(loadSystemHealthIdle())
    }
  }
}
