import produce from 'immer'
import moment from 'moment'

import {
  LOAD_INCIDENCE_DATA_REQUEST,
  LOAD_INCIDENCE_DATA_SUCCESS,
  LOAD_INCIDENCE_DATA_FAILURE,
  LOAD_INCIDENCE_DATA_IDLE,
  LOAD_INCIDENCE_DATA_HEATMAP_REQUEST,
  LOAD_INCIDENCE_DATA_HEATMAP_SUCCESS,
  LOAD_INCIDENCE_DATA_HEATMAP_FAILURE,
  LOAD_INCIDENCE_DATA_HEATMAP_IDLE,
  LOAD_LIST_INCIDENCE_PAGINATION_REQUEST,
  LOAD_LIST_INCIDENCE_PAGINATION_SUCCESS,
  LOAD_LIST_INCIDENCE_PAGINATION_FAILURE,
  LOAD_LIST_INCIDENCE_PAGINATION_IDLE,
  LOAD_LIST_ZONE_REQUEST,
  LOAD_LIST_ZONE_SUCCESS,
  LOAD_LIST_ZONE_FAILURE,
  LOAD_LIST_ZONE_IDLE,
} from '../../actions/incidence'

import { SOCKET_INCIDENCE_DATA } from '../../actions/socket'

import { FETCH_STATUS_IDLE, FETCH_STATUS_REQUEST, FETCH_STATUS_SUCCESS, FETCH_STATUS_FAILURE } from '../../utils'

const initialState = {
  listZone: [],
  listCalendarHeatMapData: [],
  totalCameraCount: 0,
  totalYearIncidenceCount: 0,
  currentDate: undefined,
  currentZone: undefined,
  filterType: undefined,
  listIncidenceCase: [],
  loadIncidenceData: {
    fetchStatus: FETCH_STATUS_IDLE,
    error: undefined,
  },
  loadIncidenceDataHeatMap: {
    fetchStatus: FETCH_STATUS_IDLE,
    error: undefined,
  },
  loadListIncidencePagination: {
    fetchStatus: FETCH_STATUS_IDLE,
    error: undefined,
  },
  loadListZone: {
    fetchStatus: FETCH_STATUS_IDLE,
    error: undefined,
  },
}

const incidence = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_LIST_ZONE_REQUEST: {
      return produce(state, draftState => {
        draftState.loadListZone.fetchStatus = FETCH_STATUS_REQUEST
      })
    }
    case LOAD_LIST_ZONE_SUCCESS: {
      return produce(state, draftState => {
        draftState.loadListZone.fetchStatus = FETCH_STATUS_SUCCESS
        draftState.listZone = action.payload.listZone.map(zoneData => ({ ...zoneData, text: zoneData.name, value: zoneData.id }))
      })
    }
    case LOAD_LIST_ZONE_FAILURE: {
      return produce(state, draftState => {
        draftState.loadListZone.fetchStatus = FETCH_STATUS_FAILURE
        draftState.loadListZone.error = action.payload.error
      })
    }
    case LOAD_LIST_ZONE_IDLE: {
      return produce(state, draftState => {
        draftState.loadListZone.fetchStatus = FETCH_STATUS_IDLE
      })
    }
    case LOAD_INCIDENCE_DATA_REQUEST: {
      return produce(state, draftState => {
        draftState.loadIncidenceData.fetchStatus = FETCH_STATUS_REQUEST
        draftState.currentZone = action.payload.zone
        draftState.currentDate = action.payload.date
        draftState.filterType = action.payload.incidentType
      })
    }
    case LOAD_INCIDENCE_DATA_SUCCESS: {
      return produce(state, draftState => {
        draftState.loadIncidenceData.fetchStatus = FETCH_STATUS_SUCCESS
        draftState.listIncidenceCase = action.payload.listIncidenceCase.sort((a, b) => moment(b.timestamp).toDate() - moment(a.timestamp).toDate())
      })
    }
    case LOAD_INCIDENCE_DATA_FAILURE: {
      return produce(state, draftState => {
        draftState.loadIncidenceData.fetchStatus = FETCH_STATUS_FAILURE
        draftState.loadIncidenceData.error = action.payload.error
      })
    }
    case LOAD_INCIDENCE_DATA_IDLE: {
      return produce(state, draftState => {
        draftState.loadIncidenceData.fetchStatus = FETCH_STATUS_IDLE
      })
    }
    case LOAD_INCIDENCE_DATA_HEATMAP_REQUEST: {
      return produce(state, draftState => {
        draftState.loadIncidenceDataHeatMap.fetchStatus = FETCH_STATUS_REQUEST
      })
    }
    case LOAD_INCIDENCE_DATA_HEATMAP_SUCCESS: {
      return produce(state, draftState => {
        draftState.loadIncidenceDataHeatMap.fetchStatus = FETCH_STATUS_SUCCESS
        draftState.listCalendarHeatMapData = action.payload.listIncidenceYearData.map(data => ({ day: data.date, value: data.count }))
        draftState.totalYearIncidenceCount = action.payload.totalIncidenceCount
        draftState.totalCameraCount = action.payload.listCamera.length
      })
    }
    case LOAD_INCIDENCE_DATA_HEATMAP_FAILURE: {
      return produce(state, draftState => {
        draftState.loadIncidenceDataHeatMap.fetchStatus = FETCH_STATUS_FAILURE
        draftState.loadIncidenceDataHeatMap.error = action.payload.error
      })
    }
    case LOAD_INCIDENCE_DATA_HEATMAP_IDLE: {
      return produce(state, draftState => {
        draftState.loadIncidenceDataHeatMap.fetchStatus = FETCH_STATUS_IDLE
      })
    }
    case LOAD_LIST_INCIDENCE_PAGINATION_REQUEST: {
      return produce(state, draftState => {
        draftState.loadListIncidencePagination.fetchStatus = FETCH_STATUS_REQUEST
      })
    }
    case LOAD_LIST_INCIDENCE_PAGINATION_SUCCESS: {
      return produce(state, draftState => {
        const preparedNextIncidenceCase = action.payload.nextListIncidence.sort((a, b) => moment(b.timestamp).toDate() - moment(a.timestamp).toDate())
        draftState.loadListIncidencePagination.fetchStatus = FETCH_STATUS_SUCCESS
        draftState.listIncidenceCase = [...state.listIncidenceCase, ...preparedNextIncidenceCase]
      })
    }
    case LOAD_LIST_INCIDENCE_PAGINATION_FAILURE: {
      return produce(state, draftState => {
        draftState.loadListIncidencePagination.fetchStatus = FETCH_STATUS_FAILURE
        draftState.loadListIncidencePagination.error = action.payload.error
      })
    }
    case LOAD_LIST_INCIDENCE_PAGINATION_IDLE: {
      return produce(state, draftState => {
        draftState.loadListIncidencePagination.fetchStatus = FETCH_STATUS_IDLE
      })
    }
    case SOCKET_INCIDENCE_DATA: {
      return produce(state, draftState => {
        draftState.listCalendarHeatMapData = action.payload.listIncidenceYearData.map(data => ({ day: data.date, value: data.count }))
        draftState.totalYearIncidenceCount = action.payload.totalIncidenceCount
        draftState.listIncidenceCase = [action.payload.newIncidenceCase, ...state.listIncidenceCase]
      })
    }
    default: {
      return state
    }
  }
}

export default incidence
