import thunk from 'redux-thunk'
import { createWrapper } from 'next-redux-wrapper'
import { applyMiddleware, compose, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import { createReduxEnhancer } from '@sentry/react'
import createSocketMiddleware from './middlewares/socket'
import rootReducer from '../reducers'

const debug = process.env.REDUX_DEBUG

const sentryReduxEnhancer = createReduxEnhancer({})

const configureStore = () => {
  const createStoreWithMiddleware = applyMiddleware(thunk, createSocketMiddleware())
  let store = createStore(rootReducer, compose(createStoreWithMiddleware, sentryReduxEnhancer))
  if (process.env.NODE_ENV === 'development') {
    store = createStore(rootReducer, composeWithDevTools(createStoreWithMiddleware))
  }
  return store
}

export default createWrapper(configureStore, { debug })
