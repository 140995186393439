import queryString from 'query-string'
import { get, patch, ERROR_ABORT_CONTROLLER } from '../../../utils'
import { API_LIST_VISITOR_HISTORY, API_UPDATE_VISITOR } from '../../api-constraint'

export const GET_ALL_VISITORS_REQUEST = 'GET_ALL_VISITORS_REQUEST'
export const GET_ALL_VISITORS_SUCCESS = 'GET_ALL_VISITORS_SUCCESS'
export const GET_ALL_VISITORS_FAILURE = 'GET_ALL_VISITORS_FAILURE'
export const GET_ALL_VISITORS_IDLE = 'GET_ALL_VISITORS_IDLE'

export const getAllVisitorsRequest = () => {
  return {
    type: GET_ALL_VISITORS_REQUEST,
  }
}

export const getAllVisitorsSuccess = listAllVisitors => {
  return {
    type: GET_ALL_VISITORS_SUCCESS,
    payload: {
      listAllVisitors,
    },
  }
}

export const getAllVisitorsFailure = error => {
  return {
    type: GET_ALL_VISITORS_FAILURE,
    payload: {
      error,
    },
  }
}

export const getAllVisitorsIdle = () => {
  return {
    type: GET_ALL_VISITORS_IDLE,
  }
}

export const getAllVisitors = ({
  cookie,
  cameraId,
  timePeriodType,
  date,
  isAllDay,
  fromTime,
  toTime,
  vehicleType,
  purposeId,
  company,
  sortBy,
  signal,
}) => {
  return async dispatch => {
    dispatch(getAllVisitorsRequest())
    try {
      const queryStr = queryString.stringify({ cameraId, timePeriodType, date, isAllDay, fromTime, toTime, vehicleType, purposeId, company, sortBy })
      const result = await get({
        path: `${API_LIST_VISITOR_HISTORY}?${queryStr}`,
        headers: {
          cookie,
        },
        signal,
      })

      dispatch(getAllVisitorsSuccess(result.data))
      dispatch(getAllVisitorsIdle())
    } catch (err) {
      if (err.name === ERROR_ABORT_CONTROLLER) {
        dispatch(getAllVisitorsRequest())
      } else {
        dispatch(getAllVisitorsFailure(err))
        dispatch(getAllVisitorsIdle())
      }
    }
  }
}

export const UPDATE_VISITOR_HISTORY_REQUEST = 'UPDATE_VISITOR_HISTORY_REQUEST'
export const UPDATE_VISITOR_HISTORY_SUCCESS = 'UPDATE_VISITOR_HISTORY_SUCCESS'
export const UPDATE_VISITOR_HISTORY_FAILURE = 'UPDATE_VISITOR_HISTORY_FAILURE'
export const UPDATE_VISITOR_HISTORY_IDLE = 'UPDATE_VISITOR_HISTORY_IDLE'

export const updateVisitorHistoryRequest = () => {
  return {
    type: UPDATE_VISITOR_HISTORY_REQUEST,
  }
}

export const updateVisitorHistorySuccess = updatedVisitorData => {
  return {
    type: UPDATE_VISITOR_HISTORY_SUCCESS,
    payload: {
      updatedVisitorData,
    },
  }
}

export const updateVisitorHistoryFailure = error => {
  return {
    type: UPDATE_VISITOR_HISTORY_FAILURE,
    payload: {
      error,
    },
  }
}

export const updateVisitorHistoryIdle = () => {
  return {
    type: UPDATE_VISITOR_HISTORY_IDLE,
  }
}

export const updateVisitorHistory = data => {
  return async dispatch => {
    dispatch(updateVisitorHistoryRequest())
    try {
      const updatedVisitor = await patch({
        path: `${API_UPDATE_VISITOR}/${data.id}`,
        body: data,
      })
      dispatch(updateVisitorHistorySuccess(updatedVisitor))
    } catch (err) {
      dispatch(updateVisitorHistoryFailure(err))
    } finally {
      dispatch(updateVisitorHistoryIdle())
    }
  }
}

export const LOAD_LIST_VISITOR_PAGINATION_REQUEST = 'LOAD_LIST_VISITOR_PAGINATION_REQUEST'
export const LOAD_LIST_VISITOR_PAGINATION_SUCCESS = 'LOAD_LIST_VISITOR_PAGINATION_SUCCESS'
export const LOAD_LIST_VISITOR_PAGINATION_FAILURE = 'LOAD_LIST_VISITOR_PAGINATION_FAILURE'
export const LOAD_LIST_VISITOR_PAGINATION_IDLE = 'LOAD_LIST_VISITOR_PAGINATION_IDLE'

export const loadListVisitorPaginationRequest = () => {
  return {
    type: LOAD_LIST_VISITOR_PAGINATION_REQUEST,
  }
}

export const loadListVisitorPaginationSuccess = listNextVisitor => {
  return {
    type: LOAD_LIST_VISITOR_PAGINATION_SUCCESS,
    payload: {
      listNextVisitor,
    },
  }
}

export const loadListVisitorPaginationFailure = error => {
  return {
    type: LOAD_LIST_VISITOR_PAGINATION_FAILURE,
    payload: {
      error,
    },
  }
}

export const loadListVisitorPaginationIdle = () => {
  return {
    type: LOAD_LIST_VISITOR_PAGINATION_IDLE,
  }
}

export const loadListVisitorPagination = ({
  cameraId,
  timePeriodType,
  date,
  isAllDay,
  fromTime,
  toTime,
  vehicleType,
  purposeId,
  company,
  sortBy,
  beforeDate,
  afterDate,
  signal,
}) => {
  return async dispatch => {
    dispatch(loadListVisitorPaginationRequest())
    try {
      if (beforeDate) {
        const queryStr = queryString.stringify({
          cameraId,
          timePeriodType,
          date,
          isAllDay,
          fromTime,
          toTime,
          vehicleType,
          purposeId,
          company,
          beforeDate,
          sortBy,
        })
        const result = await get({
          path: `${API_LIST_VISITOR_HISTORY}?${queryStr}`,
          signal,
        })
        dispatch(loadListVisitorPaginationSuccess(result.data))
      }
      if (afterDate) {
        const queryStr = queryString.stringify({
          cameraId,
          timePeriodType,
          date,
          isAllDay,
          fromTime,
          toTime,
          vehicleType,
          purposeId,
          company,
          afterDate,
          sortBy,
        })
        const result = await get({
          path: `${API_LIST_VISITOR_HISTORY}?${queryStr}`,
          signal,
        })
        dispatch(loadListVisitorPaginationSuccess(result.data))
      }
      dispatch(loadListVisitorPaginationIdle())
    } catch (error) {
      if (error.name === ERROR_ABORT_CONTROLLER) {
        dispatch(loadListVisitorPaginationRequest())
      } else {
        dispatch(loadListVisitorPaginationFailure(error))
        dispatch(loadListVisitorPaginationIdle())
      }
    }
  }
}
