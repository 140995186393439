import produce from 'immer'

import {
  SOCKET_CONNECTED,
  SOCKET_DISCONNECTED,
  SOCKET_REQUEST_CHECK_LICENSE_PLATE,
  SOCKET_RESPONSE_CHECK_LICENSE_PLATE,
  SOCKET_CHECK_LICENSE_PLATE_IDLE,
  SOCKET_REQUEST_SEARCH_LIST_MEMBERS,
  SOCKET_RESPONSE_SEARCH_LIST_MEMBERS,
  SOCKET_SEARCH_LIST_MEMBERS_IDLE,
  SOCKET_REQUEST_SEARCH_LIST_DESTINATION,
  SOCKET_RESPONSE_SEARCH_LIST_DESTINATION,
  SOCKET_SEARCH_LIST_DESTINATION_IDLE,
} from '../../actions/socket'

import { FETCH_STATUS_REQUEST, FETCH_STATUS_SUCCESS, FETCH_STATUS_IDLE } from '../../utils'

const initialState = {
  isSocketConnect: false,
  checkLicensePlate: {
    status: FETCH_STATUS_IDLE,
    member: null,
  },
  searchMember: {
    status: FETCH_STATUS_IDLE,
    search: '',
    order: 'DESC',
    sortBy: 'createdAt',
    limit: 10,
    listMember: [],
    totalItems: 0,
    totalPages: 1,
    page: 1,
  },
  searchDestination: {
    status: FETCH_STATUS_IDLE,
    search: '',
    order: 'DESC',
    sortBy: 'createdAt',
    limit: 10,
    listTenant: [],
    totalItems: 0,
    totalPages: 1,
    page: 1,
  },
}

const socketReducer = (state = initialState, action) => {
  const { type } = action
  switch (type) {
    case SOCKET_CONNECTED: {
      return produce(state, draftState => {
        draftState.isSocketConnect = true
      })
    }
    case SOCKET_DISCONNECTED: {
      return produce(state, draftState => {
        draftState.isSocketConnect = false
      })
    }
    case SOCKET_REQUEST_CHECK_LICENSE_PLATE: {
      return produce(state, draftState => {
        draftState.checkLicensePlate.status = FETCH_STATUS_REQUEST
      })
    }
    case SOCKET_RESPONSE_CHECK_LICENSE_PLATE: {
      return produce(state, draftState => {
        draftState.checkLicensePlate.status = FETCH_STATUS_SUCCESS
        draftState.checkLicensePlate.member = action.payload.member
      })
    }
    case SOCKET_CHECK_LICENSE_PLATE_IDLE: {
      return produce(state, draftState => {
        draftState.checkLicensePlate.status = FETCH_STATUS_IDLE
      })
    }
    case SOCKET_REQUEST_SEARCH_LIST_MEMBERS: {
      return produce(state, draftState => {
        draftState.searchMember.status = FETCH_STATUS_REQUEST
        draftState.searchMember.search = action.payload.search
        draftState.searchMember.page = action.payload.page
        draftState.searchMember.order = action.payload.order
        draftState.searchMember.sortBy = action.payload.sortBy
        draftState.searchMember.limit = action.payload.limit
      })
    }
    case SOCKET_RESPONSE_SEARCH_LIST_MEMBERS: {
      return produce(state, draftState => {
        draftState.searchMember.status = FETCH_STATUS_SUCCESS
        draftState.searchMember.listMember = action.payload.data
        draftState.searchMember.totalItems = action.payload.totalItems
        draftState.searchMember.totalPages = action.payload.totalPages
        draftState.searchMember.page = action.payload.page
      })
    }
    case SOCKET_SEARCH_LIST_MEMBERS_IDLE: {
      return produce(state, draftState => {
        draftState.searchMember.status = FETCH_STATUS_IDLE
      })
    }
    case SOCKET_REQUEST_SEARCH_LIST_DESTINATION: {
      return produce(state, draftState => {
        draftState.searchDestination.status = FETCH_STATUS_REQUEST
        draftState.searchDestination.search = action.payload.search
        draftState.searchDestination.page = action.payload.page
        draftState.searchDestination.order = action.payload.order
        draftState.searchDestination.sortBy = action.payload.sortBy
        draftState.searchDestination.limit = action.payload.limit
      })
    }
    case SOCKET_RESPONSE_SEARCH_LIST_DESTINATION: {
      return produce(state, draftState => {
        draftState.searchDestination.status = FETCH_STATUS_SUCCESS
        draftState.searchDestination.listTenant = action.payload.data
        draftState.searchDestination.totalItems = action.payload.totalItems
        draftState.searchDestination.totalPages = action.payload.totalPages
        draftState.searchDestination.page = action.payload.page
      })
    }
    case SOCKET_SEARCH_LIST_DESTINATION_IDLE: {
      return produce(state, draftState => {
        draftState.searchDestination.status = FETCH_STATUS_IDLE
      })
    }
    default: {
      return state
    }
  }
}

export default socketReducer
