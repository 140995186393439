import { get, patch, post, del } from '../../utils'
import { API_CAMERA_WEBHOOK, API_CREATE_CAMERA_WEBHOOK, API_WEBHOOK_METHOD, API_WEBHOOK_EVENT } from '../api-constraint'

export const LOAD_LIST_WEBHOOK_REQUEST = 'LOAD_LIST_WEBHOOK_REQUEST'
export const LOAD_LIST_WEBHOOK_SUCCESS = 'LOAD_LIST_WEBHOOK_SUCCESS'
export const LOAD_LIST_WEBHOOK_FAILURE = 'LOAD_LIST_WEBHOOK_FAILURE'
export const LOAD_LIST_WEBHOOK_IDLE = 'LOAD_LIST_WEBHOOK_IDLE'

export const loadListCameraWebhookRequest = () => {
  return {
    type: LOAD_LIST_WEBHOOK_REQUEST,
  }
}

export const loadListCameraWebhookSuccess = listCameraWebhook => {
  return {
    type: LOAD_LIST_WEBHOOK_SUCCESS,
    payload: {
      listCameraWebhook,
    },
  }
}

export const loadListCameraWebhookFailure = error => {
  return {
    type: LOAD_LIST_WEBHOOK_FAILURE,
    payload: {
      error,
    },
  }
}

export const loadListCameraWebhookIdle = () => {
  return {
    type: LOAD_LIST_WEBHOOK_IDLE,
  }
}

export const loadListCameraWebhook = (options = {}) => {
  return async dispatch => {
    const { cookie } = options
    dispatch(loadListCameraWebhookRequest())
    try {
      const listCameraWebhook = await get({
        path: `${API_CAMERA_WEBHOOK}`,
        headers: cookie ? { cookie } : undefined,
      })
      dispatch(loadListCameraWebhookSuccess(listCameraWebhook))
    } catch (err) {
      dispatch(loadListCameraWebhookFailure(err))
    } finally {
      dispatch(loadListCameraWebhookIdle())
    }
  }
}

export const CREATE_WEBHOOK_REQUEST = 'CREATE_WEBHOOK_REQUEST'
export const CREATE_WEBHOOK_SUCCESS = 'CREATE_WEBHOOK_SUCCESS'
export const CREATE_WEBHOOK_FAILURE = 'CREATE_WEBHOOK_FAILURE'
export const CREATE_WEBHOOK_IDLE = 'CREATE_WEBHOOK_IDLE'

export const createCameraWebhookRequest = () => {
  return {
    type: CREATE_WEBHOOK_REQUEST,
  }
}

export const createCameraWebhookSuccess = (webhookData, cameraId) => {
  return {
    type: CREATE_WEBHOOK_SUCCESS,
    payload: {
      webhookData,
      cameraId,
    },
  }
}

export const createCameraWebhookFailure = error => {
  return {
    type: CREATE_WEBHOOK_FAILURE,
    payload: {
      error,
    },
  }
}

export const createCameraWebhookIdle = () => {
  return {
    type: CREATE_WEBHOOK_IDLE,
  }
}

export const createCameraWebhook = (data, cameraId) => {
  return async dispatch => {
    dispatch(createCameraWebhookRequest())
    try {
      const webhookData = await post({
        path: `${API_CREATE_CAMERA_WEBHOOK.replace(':id', cameraId)}`,
        body: data,
      })
      dispatch(createCameraWebhookSuccess(webhookData, cameraId))
    } catch (err) {
      dispatch(createCameraWebhookFailure(err))
    } finally {
      dispatch(createCameraWebhookIdle())
    }
  }
}

export const UPDATE_WEBHOOK_REQUEST = 'UPDATE_WEBHOOK_REQUEST'
export const UPDATE_WEBHOOK_SUCCESS = 'UPDATE_WEBHOOK_SUCCESS'
export const UPDATE_WEBHOOK_FAILURE = 'UPDATE_WEBHOOK_FAILURE'
export const UPDATE_WEBHOOK_IDLE = 'UPDATE_WEBHOOK_IDLE'

export const updateCameraWebhookRequest = () => {
  return {
    type: UPDATE_WEBHOOK_REQUEST,
  }
}

export const updateCameraWebhookSuccess = (webhookData, cameraId) => {
  return {
    type: UPDATE_WEBHOOK_SUCCESS,
    payload: {
      webhookData,
      cameraId,
    },
  }
}

export const updateCameraWebhookFailure = error => {
  return {
    type: UPDATE_WEBHOOK_FAILURE,
    payload: {
      error,
    },
  }
}

export const updateCameraWebhookIdle = () => {
  return {
    type: UPDATE_WEBHOOK_IDLE,
  }
}

export const updateCameraWebhook = (data, cameraId) => {
  return async dispatch => {
    dispatch(updateCameraWebhookRequest())
    try {
      const updatedWebhookData = await patch({
        path: `${API_CAMERA_WEBHOOK}/${data.id}`,
        body: data,
      })
      dispatch(updateCameraWebhookSuccess(updatedWebhookData, cameraId))
    } catch (err) {
      dispatch(updateCameraWebhookFailure(err))
    } finally {
      dispatch(updateCameraWebhookIdle())
    }
  }
}

export const DELETE_WEBHOOK_REQUEST = 'DELETE_WEBHOOK_REQUEST'
export const DELETE_WEBHOOK_SUCCESS = 'DELETE_WEBHOOK_SUCCESS'
export const DELETE_WEBHOOK_FAILURE = 'DELETE_WEBHOOK_FAILURE'
export const DELETE_WEBHOOK_IDLE = 'DELETE_WEBHOOK_IDLE'

export const deleteCameraWebhookRequest = () => {
  return {
    type: DELETE_WEBHOOK_REQUEST,
  }
}

export const deleteCameraWebhookSuccess = (webhookData, cameraId) => {
  return {
    type: DELETE_WEBHOOK_SUCCESS,
    payload: {
      webhookData,
      cameraId,
    },
  }
}

export const deleteCameraWebhookFailure = error => {
  return {
    type: DELETE_WEBHOOK_FAILURE,
    payload: {
      error,
    },
  }
}

export const deleteCameraWebhookIdle = () => {
  return {
    type: DELETE_WEBHOOK_IDLE,
  }
}

export const deleteCameraWebhook = (data, cameraId) => {
  return async dispatch => {
    dispatch(deleteCameraWebhookRequest())
    try {
      await del({
        path: `${API_CAMERA_WEBHOOK}/${data.id}`,
      })
      dispatch(deleteCameraWebhookSuccess(data, cameraId))
    } catch (err) {
      dispatch(deleteCameraWebhookFailure(err))
    } finally {
      dispatch(deleteCameraWebhookIdle())
    }
  }
}

export const LOAD_WEBHOOK_OPTIONS_REQUEST = 'LOAD_WEBHOOK_OPTIONS_REQUEST'
export const LOAD_WEBHOOK_OPTIONS_SUCCESS = 'LOAD_WEBHOOK_OPTIONS_SUCCESS'
export const LOAD_WEBHOOK_OPTIONS_FAILURE = 'LOAD_WEBHOOK_OPTIONS_FAILURE'
export const LOAD_WEBHOOK_OPTIONS_IDLE = 'LOAD_WEBHOOK_OPTIONS_IDLE'

export const loadWebhookOptionsRequest = () => {
  return {
    type: LOAD_WEBHOOK_OPTIONS_REQUEST,
  }
}

export const loadWebhookOptionsSuccess = ({ listMethod, listEvent }) => {
  return {
    type: LOAD_WEBHOOK_OPTIONS_SUCCESS,
    payload: {
      listMethod,
      listEvent,
    },
  }
}

export const loadWebhookOptionsFailure = error => {
  return {
    type: LOAD_WEBHOOK_OPTIONS_FAILURE,
    payload: {
      error,
    },
  }
}

export const loadWebhookOptionsIdle = () => {
  return {
    type: LOAD_WEBHOOK_OPTIONS_IDLE,
  }
}

export const loadWebhookOptions = (options = {}) => {
  return async dispatch => {
    const { cookie } = options
    dispatch(loadWebhookOptionsRequest())
    try {
      const [listMethod, listEvent] = await Promise.all([
        get({
          path: `${API_WEBHOOK_METHOD}`,
          headers: cookie ? { cookie } : undefined,
        }),
        get({
          path: `${API_WEBHOOK_EVENT}`,
          headers: cookie ? { cookie } : undefined,
        }),
      ])
      dispatch(loadWebhookOptionsSuccess({ listMethod, listEvent }))
    } catch (err) {
      dispatch(loadWebhookOptionsFailure(err))
    } finally {
      dispatch(loadWebhookOptionsIdle())
    }
  }
}
