import produce from 'immer'
import {
  LOAD_LIST_WEBHOOK_REQUEST,
  LOAD_LIST_WEBHOOK_SUCCESS,
  LOAD_LIST_WEBHOOK_FAILURE,
  LOAD_LIST_WEBHOOK_IDLE,
  CREATE_WEBHOOK_REQUEST,
  CREATE_WEBHOOK_SUCCESS,
  CREATE_WEBHOOK_FAILURE,
  CREATE_WEBHOOK_IDLE,
  UPDATE_WEBHOOK_REQUEST,
  UPDATE_WEBHOOK_SUCCESS,
  UPDATE_WEBHOOK_FAILURE,
  UPDATE_WEBHOOK_IDLE,
  DELETE_WEBHOOK_REQUEST,
  DELETE_WEBHOOK_SUCCESS,
  DELETE_WEBHOOK_FAILURE,
  DELETE_WEBHOOK_IDLE,
  LOAD_WEBHOOK_OPTIONS_REQUEST,
  LOAD_WEBHOOK_OPTIONS_SUCCESS,
  LOAD_WEBHOOK_OPTIONS_FAILURE,
  LOAD_WEBHOOK_OPTIONS_IDLE,
} from '../../actions/setting'

import { FETCH_STATUS_IDLE, FETCH_STATUS_REQUEST, FETCH_STATUS_SUCCESS, FETCH_STATUS_FAILURE } from '../../utils'

const initialState = {
  listCameraWebhook: [],
  listMethod: [],
  listEvent: [],
  loadWebhookOptions: {
    fetchStatus: FETCH_STATUS_IDLE,
    error: undefined,
  },
  loadListCameraWebhook: {
    fetchStatus: FETCH_STATUS_IDLE,
    error: undefined,
  },
  createCameraWebhook: {
    fetchStatus: FETCH_STATUS_IDLE,
    error: undefined,
  },
  updateCameraWebhook: {
    fetchStatus: FETCH_STATUS_IDLE,
    error: undefined,
  },
  deleteCameraWebhook: {
    fetchStatus: FETCH_STATUS_IDLE,
    error: undefined,
  },
}

const setting = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_LIST_WEBHOOK_REQUEST: {
      return produce(state, draftState => {
        draftState.loadListCameraWebhook.fetchStatus = FETCH_STATUS_REQUEST
      })
    }
    case LOAD_LIST_WEBHOOK_SUCCESS: {
      return produce(state, draftState => {
        draftState.loadListCameraWebhook.fetchStatus = FETCH_STATUS_SUCCESS
        draftState.listCameraWebhook = action.payload.listCameraWebhook
      })
    }
    case LOAD_LIST_WEBHOOK_FAILURE: {
      return produce(state, draftState => {
        draftState.loadListCameraWebhook.fetchStatus = FETCH_STATUS_FAILURE
        draftState.loadListCameraWebhook.error = action.payload.error
      })
    }
    case LOAD_LIST_WEBHOOK_IDLE: {
      return produce(state, draftState => {
        draftState.loadListCameraWebhook.fetchStatus = FETCH_STATUS_IDLE
      })
    }
    case CREATE_WEBHOOK_REQUEST: {
      return produce(state, draftState => {
        draftState.createCameraWebhook.fetchStatus = FETCH_STATUS_REQUEST
      })
    }
    case CREATE_WEBHOOK_SUCCESS: {
      return produce(state, draftState => {
        const preapredListCameraWebhook = state.listCameraWebhook.map(cameraData => {
          if (cameraData.id === action.payload.cameraId) {
            return {
              ...cameraData,
              webhooks: [...cameraData.webhooks, action.payload.webhookData],
            }
          }
          return cameraData
        })
        draftState.createCameraWebhook.fetchStatus = FETCH_STATUS_SUCCESS
        draftState.listCameraWebhook = preapredListCameraWebhook
      })
    }
    case CREATE_WEBHOOK_FAILURE: {
      return produce(state, draftState => {
        draftState.createCameraWebhook.fetchStatus = FETCH_STATUS_FAILURE
        draftState.createCameraWebhook.error = action.payload.error
      })
    }
    case CREATE_WEBHOOK_IDLE: {
      return produce(state, draftState => {
        draftState.createCameraWebhook.fetchStatus = FETCH_STATUS_IDLE
      })
    }
    case UPDATE_WEBHOOK_REQUEST: {
      return produce(state, draftState => {
        draftState.updateCameraWebhook.fetchStatus = FETCH_STATUS_REQUEST
      })
    }
    case UPDATE_WEBHOOK_SUCCESS: {
      return produce(state, draftState => {
        const preapredListCameraWebhook = state.listCameraWebhook.map(cameraData => {
          if (cameraData.id === action.payload.cameraId) {
            return {
              ...cameraData,
              webhooks: cameraData.webhooks.map(webhookData => {
                if (webhookData.id === action.payload.webhookData.id) {
                  return action.payload.webhookData
                }
                return webhookData
              }),
            }
          }
          return cameraData
        })
        draftState.updateCameraWebhook.fetchStatus = FETCH_STATUS_SUCCESS
        draftState.listCameraWebhook = preapredListCameraWebhook
      })
    }
    case UPDATE_WEBHOOK_FAILURE: {
      return produce(state, draftState => {
        draftState.updateCameraWebhook.fetchStatus = FETCH_STATUS_FAILURE
        draftState.updateCameraWebhook.error = action.payload.error
      })
    }
    case UPDATE_WEBHOOK_IDLE: {
      return produce(state, draftState => {
        draftState.updateCameraWebhook.fetchStatus = FETCH_STATUS_IDLE
      })
    }
    case DELETE_WEBHOOK_REQUEST: {
      return produce(state, draftState => {
        draftState.deleteCameraWebhook.fetchStatus = FETCH_STATUS_REQUEST
      })
    }
    case DELETE_WEBHOOK_SUCCESS: {
      return produce(state, draftState => {
        const preapredListCameraWebhook = state.listCameraWebhook.map(cameraData => {
          if (cameraData.id === action.payload.cameraId) {
            return {
              ...cameraData,
              webhooks: cameraData.webhooks.filter(webhookData => webhookData.id !== action.payload.webhookData.id),
            }
          }
          return cameraData
        })
        draftState.deleteCameraWebhook.fetchStatus = FETCH_STATUS_SUCCESS
        draftState.listCameraWebhook = preapredListCameraWebhook
      })
    }
    case DELETE_WEBHOOK_FAILURE: {
      return produce(state, draftState => {
        draftState.deleteCameraWebhook.fetchStatus = FETCH_STATUS_FAILURE
        draftState.deleteCameraWebhook.error = action.payload.error
      })
    }
    case DELETE_WEBHOOK_IDLE: {
      return produce(state, draftState => {
        draftState.deleteCameraWebhook.fetchStatus = FETCH_STATUS_IDLE
      })
    }
    case LOAD_WEBHOOK_OPTIONS_REQUEST: {
      return produce(state, draftState => {
        draftState.loadWebhookOptions.fetchStatus = FETCH_STATUS_REQUEST
      })
    }
    case LOAD_WEBHOOK_OPTIONS_SUCCESS: {
      return produce(state, draftState => {
        draftState.loadWebhookOptions.fetchStatus = FETCH_STATUS_SUCCESS
        draftState.listMethod = action.payload.listMethod.map(data => {
          return {
            text: data,
            value: data,
          }
        })
        draftState.listEvent = action.payload.listEvent.map(data => {
          return {
            text: data,
            value: data,
          }
        })
      })
    }
    case LOAD_WEBHOOK_OPTIONS_FAILURE: {
      return produce(state, draftState => {
        draftState.loadWebhookOptions.fetchStatus = FETCH_STATUS_FAILURE
        draftState.loadWebhookOptions.error = action.payload.error
      })
    }
    case LOAD_WEBHOOK_OPTIONS_IDLE: {
      return produce(state, draftState => {
        draftState.loadWebhookOptions.fetchStatus = FETCH_STATUS_IDLE
      })
    }
    default: {
      return state
    }
  }
}

export default setting
