import { combineReducers } from 'redux'
import { HYDRATE } from 'next-redux-wrapper'
import auth from './auth'
import application from './application'
import incidence from './incidence'
import trafficcontrol from './trafficcontrol'
import visitor from './visitor'
import setting from './setting'
import socket from './socket'
import userManagement from './user-management'
import member from './member'
import tenant from './tenant'
import systemHealth from './system-health'

const appCombineReducers = combineReducers({
  auth,
  application,
  incidence,
  trafficcontrol,
  visitor,
  setting,
  userManagement,
  socket,
  member,
  tenant,
  systemHealth,
})

const rootReducer = (state, action) => {
  if (action.type === HYDRATE) {
    return {
      ...state,
      ...action.payload,
    }
  }
  return appCombineReducers(state, action)
}

export default rootReducer
