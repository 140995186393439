import queryString from 'query-string'
import { get, ERROR_ABORT_CONTROLLER } from '../../utils'
import { API_LIST_ZONE, API_LIST_CAMERA, API_LIST_INCIDENCE_YEAR, API_LIST_INCIDENCE_YEAR_COUNT, API_LIST_INCIDENCE_CASE } from '../api-constraint'

export const LOAD_LIST_ZONE_REQUEST = 'LOAD_LIST_ZONE_REQUEST'
export const LOAD_LIST_ZONE_SUCCESS = 'LOAD_LIST_ZONE_SUCCESS'
export const LOAD_LIST_ZONE_FAILURE = 'LOAD_LIST_ZONE_FAILURE'
export const LOAD_LIST_ZONE_IDLE = 'LOAD_LIST_ZONE_IDLE'

export const loadListZoneRequest = () => {
  return {
    type: LOAD_LIST_ZONE_REQUEST,
  }
}

export const loadListZoneSuccess = listZone => {
  return {
    type: LOAD_LIST_ZONE_SUCCESS,
    payload: {
      listZone,
    },
  }
}

export const loadListZoneFailure = error => {
  return {
    type: LOAD_LIST_ZONE_FAILURE,
    payload: {
      error,
    },
  }
}

export const loadListZoneIdle = () => {
  return {
    type: LOAD_LIST_ZONE_IDLE,
  }
}

export const loadListZone = (options = {}) => {
  return async dispatch => {
    const { cookie, cameraTypes } = options
    dispatch(loadListZoneRequest())
    try {
      const queryText = cameraTypes ? `?${queryString.stringify({ cameraTypes })}` : ''
      const listZone = await get({
        path: `${API_LIST_ZONE}${queryText}`,
        headers: cookie ? { cookie } : undefined,
      })
      dispatch(loadListZoneSuccess(listZone))
    } catch (err) {
      dispatch(loadListZoneFailure(err))
    } finally {
      dispatch(loadListZoneIdle())
    }
  }
}

export const LOAD_INCIDENCE_DATA_REQUEST = 'LOAD_INCIDENCE_DATA_REQUEST'
export const LOAD_INCIDENCE_DATA_SUCCESS = 'LOAD_INCIDENCE_DATA_SUCCESS'
export const LOAD_INCIDENCE_DATA_FAILURE = 'LOAD_INCIDENCE_DATA_FAILURE'
export const LOAD_INCIDENCE_DATA_IDLE = 'LOAD_INCIDENCE_DATA_IDLE'

export const loadIncidenceDataRequest = ({ zone, date, incidentType }) => {
  return {
    type: LOAD_INCIDENCE_DATA_REQUEST,
    payload: {
      zone,
      date,
      incidentType,
    },
  }
}

export const loadIncidenceDataSuccess = ({ listIncidenceCase }) => {
  return {
    type: LOAD_INCIDENCE_DATA_SUCCESS,
    payload: {
      listIncidenceCase,
    },
  }
}

export const loadIncidenceDataFailure = error => {
  return {
    type: LOAD_INCIDENCE_DATA_FAILURE,
    payload: {
      error,
    },
  }
}

export const loadIncidenceDataIdle = () => {
  return {
    type: LOAD_INCIDENCE_DATA_IDLE,
  }
}

export const loadIncidenceData = (options = {}) => {
  return async dispatch => {
    const { cookie, zone, date, signal, incidentType } = options
    dispatch(loadIncidenceDataRequest({ zone, date, incidentType }))
    try {
      const query = queryString.stringify({
        zone,
        date,
        incidentType,
      })
      const listIncidenceCase = await get({
        path: `${API_LIST_INCIDENCE_CASE}?${query}`,
        headers: cookie ? { cookie } : undefined,
        signal,
      })
      dispatch(
        loadIncidenceDataSuccess({
          listIncidenceCase,
        }),
      )
      dispatch(loadIncidenceDataIdle())
    } catch (err) {
      if (err.name === ERROR_ABORT_CONTROLLER) {
        dispatch(loadIncidenceDataRequest({ zone, date, incidentType }))
      } else {
        dispatch(loadIncidenceDataFailure(err))
        dispatch(loadIncidenceDataIdle())
      }
    }
  }
}

export const LOAD_INCIDENCE_DATA_HEATMAP_REQUEST = 'LOAD_INCIDENCE_DATA_HEATMAP_REQUEST'
export const LOAD_INCIDENCE_DATA_HEATMAP_SUCCESS = 'LOAD_INCIDENCE_DATA_HEATMAP_SUCCESS'
export const LOAD_INCIDENCE_DATA_HEATMAP_FAILURE = 'LOAD_INCIDENCE_DATA_HEATMAP_FAILURE'
export const LOAD_INCIDENCE_DATA_HEATMAP_IDLE = 'LOAD_INCIDENCE_DATA_HEATMAP_IDLE'

export const loadIncidenceDataHeatMapRequest = () => {
  return {
    type: LOAD_INCIDENCE_DATA_HEATMAP_REQUEST,
  }
}

export const loadIncidenceDataHeatMapSuccess = ({ listCamera, listIncidenceYearData, totalIncidenceCount }) => {
  return {
    type: LOAD_INCIDENCE_DATA_HEATMAP_SUCCESS,
    payload: {
      listCamera,
      listIncidenceYearData,
      totalIncidenceCount,
    },
  }
}

export const loadIncidenceDataHeatMapFailure = error => {
  return {
    type: LOAD_INCIDENCE_DATA_HEATMAP_FAILURE,
    payload: {
      error,
    },
  }
}

export const loadIncidenceDataHeatMapIdle = () => {
  return {
    type: LOAD_INCIDENCE_DATA_HEATMAP_IDLE,
  }
}

export const loadIncidenceDataHeatMap = (options = {}) => {
  return async dispatch => {
    const { cookie, zone, date, cameraTypes, signal } = options
    dispatch(loadIncidenceDataHeatMapRequest())
    try {
      const query = queryString.stringify({
        zone,
        date,
      })
      const queryZone = queryString.stringify({
        zone,
        cameraTypes,
      })
      const [listCamera, listIncidenceYearData, totalIncidenceCount] = await Promise.all([
        get({
          path: `${API_LIST_CAMERA}?${queryZone}`,
          headers: cookie ? { cookie } : undefined,
          signal,
        }),
        get({
          path: `${API_LIST_INCIDENCE_YEAR}?${query}`,
          headers: cookie ? { cookie } : undefined,
          signal,
        }),
        get({
          path: `${API_LIST_INCIDENCE_YEAR_COUNT}?${query}`,
          headers: cookie ? { cookie } : undefined,
          signal,
        }),
      ])
      dispatch(
        loadIncidenceDataHeatMapSuccess({
          listCamera,
          listIncidenceYearData,
          totalIncidenceCount: totalIncidenceCount.data,
        }),
      )
      dispatch(loadIncidenceDataHeatMapIdle())
    } catch (err) {
      if (err.name === ERROR_ABORT_CONTROLLER) {
        dispatch(loadIncidenceDataHeatMapRequest())
      } else {
        dispatch(loadIncidenceDataHeatMapFailure(err))
        dispatch(loadIncidenceDataHeatMapIdle())
      }
    }
  }
}

export const LOAD_LIST_INCIDENCE_PAGINATION_REQUEST = 'LOAD_LIST_INCIDENCE_PAGINATION_REQUEST'
export const LOAD_LIST_INCIDENCE_PAGINATION_SUCCESS = 'LOAD_LIST_INCIDENCE_PAGINATION_SUCCESS'
export const LOAD_LIST_INCIDENCE_PAGINATION_FAILURE = 'LOAD_LIST_INCIDENCE_PAGINATION_FAILURE'
export const LOAD_LIST_INCIDENCE_PAGINATION_IDLE = 'LOAD_LIST_INCIDENCE_PAGINATION_IDLE'

export const loadListIncidencePaginationRequest = () => {
  return {
    type: LOAD_LIST_INCIDENCE_PAGINATION_REQUEST,
  }
}

export const loadListIncidencePaginationSuccess = nextListIncidence => {
  return {
    type: LOAD_LIST_INCIDENCE_PAGINATION_SUCCESS,
    payload: {
      nextListIncidence,
    },
  }
}

export const loadListIncidencePaginationFailure = error => {
  return {
    type: LOAD_LIST_INCIDENCE_PAGINATION_FAILURE,
    payload: {
      error,
    },
  }
}

export const loadListIncidencePaginationIdle = () => {
  return {
    type: LOAD_LIST_INCIDENCE_PAGINATION_IDLE,
  }
}

export const loadListIncidencePagination = ({ zone, date, paginationDate, signal, incidentType }) => {
  return async dispatch => {
    dispatch(loadListIncidencePaginationRequest())
    try {
      const query = queryString.stringify({
        zone,
        date,
        paginationDate,
        incidentType,
      })
      const nextListIncidence = await get({
        path: `${API_LIST_INCIDENCE_CASE}?${query}`,
        signal,
      })
      dispatch(loadListIncidencePaginationSuccess(nextListIncidence))
    } catch (err) {
      dispatch(loadListIncidencePaginationFailure(err))
    } finally {
      dispatch(loadListIncidencePaginationIdle())
    }
  }
}
