import React from 'react'
import App from 'next/app'
import Router from 'next/router'

import { bindActionCreators, compose } from 'redux'
import { connect } from 'react-redux'
import 'react-toastify/dist/ReactToastify.css'

import { ToastContainer } from 'react-toastify'
import reduxWrapper from '../../frontend/store'
import { loadListNotification } from '../../frontend/actions/application'
import { loadSystemHealth } from '../../frontend/actions/system-health'
import { connectSocket } from '../../frontend/actions/socket'
import { appWithTranslation } from '../../i18n'

import { initializeSentry } from '../../frontend/utils/sentry'

import '../../frontend/assets/global-style.scss'

initializeSentry()

export class MyApp extends App {
  componentDidMount() {
    window.addEventListener('storage', this.handleStorageChanged)
    this.props.connectSocket()
    this.props.loadListNotification()
    this.props.loadSystemHealth()
  }

  componentWillUnmount() {
    window.removeEventListener('storage', this.handleStorageChanged)
  }

  handleStorageChanged = event => {
    const { key, oldValue, newValue } = event
    const isLogout = oldValue && !newValue
    const isNewLogin = oldValue !== null && oldValue !== newValue
    if (key === 'email' && isLogout) {
      Router.replace('/')
    } else if (key === 'email' && isNewLogin) {
      Router.reload()
    }
  }

  render() {
    const { Component, pageProps } = this.props
    return (
      <>
        <ToastContainer
          className="toast-container"
          toastClassName="toast"
          progressClassName="toast-progress"
          position="top-right"
          autoClose={3000}
          closeButton={false}
        />
        <Component {...pageProps} hideNotificationBadge={this.hideNotificationBadge} />
      </>
    )
  }
}

/* istanbul ignore next */
const mapDispatchToProps = dispatch => {
  return bindActionCreators({ connectSocket, loadListNotification, loadSystemHealth }, dispatch)
}

export default compose(reduxWrapper.withRedux, connect(null, mapDispatchToProps), appWithTranslation)(MyApp)
