import produce from 'immer'
import moment from 'moment'
import {
  LOAD_ALL_OPTION_DROPDOWN_REQUEST,
  LOAD_ALL_OPTION_DROPDOWN_SUCCESS,
  LOAD_ALL_OPTION_DROPDOWN_FAILURE,
  LOAD_ALL_OPTION_DROPDOWN_IDLE,
  LOAD_LIST_TODAY_VISITOR_REQUEST,
  LOAD_LIST_TODAY_VISITOR_SUCCESS,
  LOAD_LIST_TODAY_VISITOR_FAILURE,
  LOAD_LIST_TODAY_VISITOR_IDLE,
  LOAD_LIST_TODAY_VISITOR_PAGINATION_REQUEST,
  LOAD_LIST_TODAY_VISITOR_PAGINATION_SUCCESS,
  LOAD_LIST_TODAY_VISITOR_PAGINATION_FAILURE,
  LOAD_LIST_TODAY_VISITOR_PAGINATION_IDLE,
  UPDATE_VISITOR_DETECTION_REQUEST,
  UPDATE_VISITOR_DETECTION_SUCCESS,
  UPDATE_VISITOR_DETECTION_FAILURE,
  UPDATE_VISITOR_DETECTION_IDLE,
} from '../../actions/visitor'

import { SOCKET_IN_COMING_VISITOR, SOCKET_UPDATE_VISITOR } from '../../actions/socket'

import {
  GET_ALL_VISITORS_FAILURE,
  GET_ALL_VISITORS_IDLE,
  GET_ALL_VISITORS_REQUEST,
  GET_ALL_VISITORS_SUCCESS,
  UPDATE_VISITOR_HISTORY_REQUEST,
  UPDATE_VISITOR_HISTORY_SUCCESS,
  UPDATE_VISITOR_HISTORY_FAILURE,
  UPDATE_VISITOR_HISTORY_IDLE,
  LOAD_LIST_VISITOR_PAGINATION_REQUEST,
  LOAD_LIST_VISITOR_PAGINATION_SUCCESS,
  LOAD_LIST_VISITOR_PAGINATION_FAILURE,
  LOAD_LIST_VISITOR_PAGINATION_IDLE,
} from '../../actions/visitor/history'

import { FETCH_STATUS_IDLE, FETCH_STATUS_REQUEST, FETCH_STATUS_SUCCESS, FETCH_STATUS_FAILURE } from '../../utils'

const initialState = {
  listTodayVisitor: [],
  listAllVisitor: [],
  listCamera: [],
  listVehicleType: [],
  listDestination: [],
  listAllDestination: [],
  listPurposeVisit: [],
  listPlateOrigin: [],
  listPlateColor: [],
  loadListDropdown: {
    fetchStatus: FETCH_STATUS_IDLE,
    error: undefined,
  },
  loadListAllVisitor: {
    fetchStatus: FETCH_STATUS_IDLE,
    error: undefined,
  },
  loadListAllVisitorPagination: {
    fetchStatus: FETCH_STATUS_IDLE,
    error: undefined,
  },
  updateHistoryVisitor: {
    fetchStatus: FETCH_STATUS_IDLE,
    error: undefined,
  },
  loadListTodayVisitor: {
    camera: undefined,
    fetchStatus: FETCH_STATUS_IDLE,
    error: undefined,
  },
  loadListTodayVisitorPagination: {
    fetchStatus: FETCH_STATUS_IDLE,
    error: undefined,
  },
  updateVisitorDetection: {
    fetchStatus: FETCH_STATUS_IDLE,
    error: undefined,
  },
}

const visitor = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_ALL_OPTION_DROPDOWN_REQUEST: {
      return produce(state, draftState => {
        draftState.loadListDropdown.fetchStatus = FETCH_STATUS_REQUEST
      })
    }
    case LOAD_ALL_OPTION_DROPDOWN_SUCCESS: {
      return produce(state, draftState => {
        draftState.loadListDropdown.fetchStatus = FETCH_STATUS_SUCCESS
        draftState.listCamera = action.payload.listCamera.map(gateData => ({ ...gateData, text: gateData.name, value: gateData.id }))
        draftState.listVehicleType = action.payload.listVehicleType.map(vehicleTypeData => ({
          ...vehicleTypeData,
          text: vehicleTypeData.name,
          value: vehicleTypeData.name,
        }))
        draftState.listDestination = action.payload.listDestination.map(destinationData => ({
          ...destinationData,
          text: destinationData.company.nameEn,
          value: destinationData.id,
        }))
        draftState.listAllDestination = action.payload.listAllDestination.map(destinationData => ({
          ...destinationData,
          text: destinationData.company.nameEn,
          value: destinationData.id,
        }))
        draftState.listPurposeVisit = action.payload.listPurposeVisit.map(purposeData => ({
          ...purposeData,
          text: purposeData.name,
          value: purposeData.id,
        }))
        draftState.listPlateOrigin = action.payload.listPlateOrigin.map(plateOriginData => ({
          text: plateOriginData,
          value: plateOriginData,
        }))
        draftState.listPlateColor = action.payload.listPlateColor.map(plateColorData => ({
          text: plateColorData,
          value: plateColorData,
        }))
      })
    }
    case LOAD_ALL_OPTION_DROPDOWN_FAILURE: {
      return produce(state, draftState => {
        draftState.loadListDropdown.fetchStatus = FETCH_STATUS_FAILURE
        draftState.loadListDropdown.error = action.payload.error
      })
    }
    case LOAD_ALL_OPTION_DROPDOWN_IDLE: {
      return produce(state, draftState => {
        draftState.loadListDropdown.fetchStatus = FETCH_STATUS_IDLE
      })
    }
    case LOAD_LIST_TODAY_VISITOR_REQUEST: {
      return produce(state, draftState => {
        draftState.loadListTodayVisitor.fetchStatus = FETCH_STATUS_REQUEST
        draftState.loadListTodayVisitor.camera = +action.payload.camera
        draftState.listTodayVisitor = []
      })
    }
    case LOAD_LIST_TODAY_VISITOR_SUCCESS: {
      return produce(state, draftState => {
        draftState.loadListTodayVisitor.fetchStatus = FETCH_STATUS_SUCCESS
        draftState.listTodayVisitor = action.payload.listTodayVisitor.sort((a, b) => moment(b.timestamp).toDate() - moment(a.timestamp).toDate())
      })
    }
    case LOAD_LIST_TODAY_VISITOR_FAILURE: {
      return produce(state, draftState => {
        draftState.loadListTodayVisitor.fetchStatus = FETCH_STATUS_FAILURE
        draftState.loadListTodayVisitor.error = action.payload.error
      })
    }
    case LOAD_LIST_TODAY_VISITOR_IDLE: {
      return produce(state, draftState => {
        draftState.loadListTodayVisitor.fetchStatus = FETCH_STATUS_IDLE
      })
    }
    case LOAD_LIST_TODAY_VISITOR_PAGINATION_REQUEST: {
      return produce(state, draftState => {
        draftState.loadListTodayVisitorPagination.fetchStatus = FETCH_STATUS_REQUEST
      })
    }
    case LOAD_LIST_TODAY_VISITOR_PAGINATION_SUCCESS: {
      return produce(state, draftState => {
        const preparedNextListTodayVisitor = action.payload.nextListTodayVisitor.sort(
          (a, b) => moment(b.timestamp).toDate() - moment(a.timestamp).toDate(),
        )
        draftState.loadListTodayVisitorPagination.fetchStatus = FETCH_STATUS_SUCCESS
        draftState.listTodayVisitor = [...state.listTodayVisitor, ...preparedNextListTodayVisitor]
      })
    }
    case LOAD_LIST_TODAY_VISITOR_PAGINATION_FAILURE: {
      return produce(state, draftState => {
        draftState.loadListTodayVisitorPagination.fetchStatus = FETCH_STATUS_FAILURE
        draftState.loadListTodayVisitorPagination.error = action.payload.error
      })
    }
    case LOAD_LIST_TODAY_VISITOR_PAGINATION_IDLE: {
      return produce(state, draftState => {
        draftState.loadListTodayVisitorPagination.fetchStatus = FETCH_STATUS_IDLE
      })
    }
    case UPDATE_VISITOR_DETECTION_REQUEST: {
      return produce(state, draftState => {
        draftState.updateVisitorDetection.fetchStatus = FETCH_STATUS_REQUEST
      })
    }
    case UPDATE_VISITOR_DETECTION_SUCCESS: {
      return produce(state, draftState => {
        draftState.updateVisitorDetection.fetchStatus = FETCH_STATUS_SUCCESS
        draftState.listTodayVisitor = draftState.listTodayVisitor.map(detectionData => {
          if (detectionData.id === action.payload.updatedVisitorDetectionData.id) {
            return {
              ...action.payload.updatedVisitorDetectionData,
            }
          }
          return detectionData
        })
      })
    }
    case UPDATE_VISITOR_DETECTION_FAILURE: {
      return produce(state, draftState => {
        draftState.updateVisitorDetection.fetchStatus = FETCH_STATUS_FAILURE
        draftState.updateVisitorDetection.error = action.payload.error
      })
    }
    case UPDATE_VISITOR_DETECTION_IDLE: {
      return produce(state, draftState => {
        draftState.updateVisitorDetection.fetchStatus = FETCH_STATUS_IDLE
      })
    }
    case SOCKET_IN_COMING_VISITOR: {
      return produce(state, draftState => {
        draftState.listTodayVisitor = [action.payload.inComingVisitor, ...state.listTodayVisitor]
      })
    }
    case SOCKET_UPDATE_VISITOR: {
      return produce(state, draftState => {
        draftState.listTodayVisitor = draftState.listTodayVisitor.map(detectionData => {
          if (detectionData.id === action.payload.updatedVisitorDetectionData.id) {
            return { ...detectionData, ...action.payload.updatedVisitorDetectionData }
          }
          return detectionData
        })
      })
    }
    case GET_ALL_VISITORS_REQUEST: {
      return produce(state, draftState => {
        draftState.loadListAllVisitor.fetchStatus = FETCH_STATUS_REQUEST
      })
    }
    case GET_ALL_VISITORS_SUCCESS: {
      return produce(state, draftState => {
        draftState.loadListAllVisitor.fetchStatus = FETCH_STATUS_SUCCESS
        draftState.listAllVisitor = action.payload.listAllVisitors
      })
    }
    case GET_ALL_VISITORS_FAILURE: {
      return produce(state, draftState => {
        draftState.loadListAllVisitor.fetchStatus = FETCH_STATUS_FAILURE
        draftState.loadListAllVisitor.error = action.payload.error
      })
    }
    case GET_ALL_VISITORS_IDLE: {
      return produce(state, draftState => {
        draftState.loadListAllVisitor.fetchStatus = FETCH_STATUS_IDLE
      })
    }
    case UPDATE_VISITOR_HISTORY_REQUEST: {
      return produce(state, draftState => {
        draftState.updateHistoryVisitor.fetchStatus = FETCH_STATUS_REQUEST
      })
    }
    case UPDATE_VISITOR_HISTORY_SUCCESS: {
      return produce(state, draftState => {
        draftState.updateHistoryVisitor.fetchStatus = FETCH_STATUS_SUCCESS
        draftState.listAllVisitor = draftState.listAllVisitor.map(visitorData => {
          if (visitorData.id === action.payload.updatedVisitorData.id) {
            return { ...action.payload.updatedVisitorData }
          }
          return visitorData
        })
      })
    }
    case UPDATE_VISITOR_HISTORY_FAILURE: {
      return produce(state, draftState => {
        draftState.updateHistoryVisitor.fetchStatus = FETCH_STATUS_FAILURE
        draftState.updateHistoryVisitor.error = action.payload.error
      })
    }
    case UPDATE_VISITOR_HISTORY_IDLE: {
      return produce(state, draftState => {
        draftState.updateHistoryVisitor.fetchStatus = FETCH_STATUS_IDLE
      })
    }
    case LOAD_LIST_VISITOR_PAGINATION_REQUEST: {
      return produce(state, draftState => {
        draftState.loadListAllVisitorPagination.fetchStatus = FETCH_STATUS_REQUEST
      })
    }
    case LOAD_LIST_VISITOR_PAGINATION_SUCCESS: {
      return produce(state, draftState => {
        draftState.loadListAllVisitorPagination.fetchStatus = FETCH_STATUS_SUCCESS
        draftState.listAllVisitor = [...state.listAllVisitor, ...action.payload.listNextVisitor]
      })
    }
    case LOAD_LIST_VISITOR_PAGINATION_FAILURE: {
      return produce(state, draftState => {
        draftState.loadListAllVisitorPagination.fetchStatus = FETCH_STATUS_FAILURE
        draftState.loadListAllVisitorPagination.error = action.payload.error
      })
    }
    case LOAD_LIST_VISITOR_PAGINATION_IDLE: {
      return produce(state, draftState => {
        draftState.loadListAllVisitorPagination.fetchStatus = FETCH_STATUS_IDLE
      })
    }
    default: {
      return state
    }
  }
}

export default visitor
